<div class="row my-1">    
      <div *ngIf="id_product==0" class="col-lg-2 pr-lg-0">
        <app-filtercontainer [title]="'Filtra per categorie o movimentazioni'" >
            <app-treecategories [multiple]="true" [class]="'card p-1'" (id_selected)="filter_id_category.value=$event;getItems()" [showAll]="true" [showCountProduct]="false" [id]="filter_id_category.value" [editButton]="false" #tree [list]="categories"></app-treecategories>
            <ul class="card small-box list-group p-1 mt-2">
                <li class="list-group-item pointer" [class.active]="filter_stock.mode==0" (click)="filter_stock.mode=0;getItems();">Tutti</li>
                <li class="list-group-item pointer" [class.active]="filter_stock.mode==6" (click)="filter_stock.mode=6;getItems();">Giacenza positiva</li>
                <li class="list-group-item pointer" [class.active]="filter_stock.mode==7 && filter_stock.value=='0'" (click)="filter_stock.mode=7;getItems();">Giacenza negativa</li>
                <li class="list-group-item pointer" [class.active]="filter_stock.mode==1" (click)="filter_stock.mode=1;getItems();">Giacenza nulla</li>
                <li class="list-group-item pointer" [class.active]="filter_stock.mode==7 && filter_stock.value=='pm.minstock'" (click)="filter_stock.mode=7;filter_stock.value='pm.minstock';filter_stock.valueIsField='true';getItems();">Giacenza da riordinare</li>
                <div class="icon"><i class="fas fa-cubes"></i></div>
            </ul>
            <ul class="card small-box list-group p-1 my-2">
                <li class="list-group-item pointer" [class.active]="filter_date_stock.mode==0" (click)="filter_date_stock.mode=0;getItems();">Tutti</li>
                <li class="list-group-item pointer" [class.active]="filter_date_stock.mode==7" (click)="filter_date_stock.mode=7">
                    Movimentati prima del...<i *ngIf="filter_date_stock.mode!=7" class="fas fa-chevron-down float-right"></i>
                    <ng-container *ngIf="filter_date_stock.mode==7">
                        <input type="date" class="form-control form-control-sm" name="filter_date_stock" (change)="getItems();" [(ngModel)]="filter_date_stock.value">
                    </ng-container>
                </li>
                <li class="list-group-item" [class.active]="filter_date_stock.mode==6" (click)="filter_date_stock.mode=6">
                    Movimentati dopo il...<i *ngIf="filter_date_stock.mode!=6" class="fas fa-chevron-down float-right"></i>
                    <ng-container *ngIf="filter_date_stock.mode==6">
                        <input type="date" class="form-control form-control-sm" name="filter_date_stock" (change)="getItems();" [(ngModel)]="filter_date_stock.value">
                    </ng-container>
                </li>
                <li *ngIf="filter_date_stock.value!=current_year+'-01-01' || filter_date_stock.mode!=6;" class="list-group-item pointer" [class.active]="" (click)="filter_date_stock.mode=6;filter_date_stock.value=current_year+'-01-01';getItems();">Movimentati in questo anno</li>
                <div class="icon"><i class="fas fa-truck-loading"></i></div>
            </ul>
        </app-filtercontainer>
    </div>
    <div [class.col-lg-10]="id_product==0" [class.col-12]="id_product>0">
        <div class="card card-outline">
            <div class="p-0">
                <div class="row" *ngIf="id_product==0">
                    <div class="col-lg-4">
                        <div class="input-group input-group-info input-group-sm p-1">
                            <input type="text" #table_search name="table_search" class="form-control form-control-sm" placeholder="cerca per marca, modello prodotto..." [(ngModel)]="filter_search.value" (keyup.enter)="getItems()">
                            <div class="input-group-append ">
                                <button type="submit" class="btn btn-default text-primary"  title="avvia ricerca" (click)="getItems()"><i class="fas fa-search"></i></button>
                                <button class="btn btn-default text-danger" *ngIf="filter_search.value!=''"  title="annulla ricerca" (click)="filter_search.value='';getItems();"><span id="searchclear" class="fas fa-times"></span></button>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="input-group input-group-sm p-1" >
                            <select class="form-control form-control-sm" [(ngModel)]="filter_id_brand.value" (ngModelChange)="getItems()" [class.alert-info]="filter_id_brand.value">
                                <option value="">Tutte le marche</option>
                                <option *ngFor=" let r of brands" [ngValue]="r.id">{{r.name}}</option>
                            </select>
                            <!-- <div class="input-group-append ">
                                <button class="btn btn-sm btn-default"  title="Gestione marche" (click)="openBrandsManager()"><i class="fa fa-cog"></i></button>
                            </div> -->
                        </div>
                    </div>
                    <div class="col-6 pl-lg-0 col-lg-3">
                        <div class="input-group input-group-sm m-1" >
                            <select class="form-control form-control-sm" [(ngModel)]="filter_id_type.value" (ngModelChange)="getItems()" [class.alert-info]="filter_id_type.valu">
                                <option value="">Tutte le tipologie</option>
                                <option *ngFor=" let r of types" [ngValue]="r.id">{{r.name}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-6 pl-lg-0 col-lg-2" >
                        <!-- <button *ngIf="user.isSuperUser()" title="Gestione magazzini (Solo utenti autorizzati)" (click)="openInventoryManager()" class="btn btn-default btn-xs float-right mr-1 mt-1"><i class="fas fa-user-cog"></i></button> -->
                        <div class="mx-1">
                            <app-windowlistbuttons 
                            [model]="this" 
                            [addButton]="false" 
                            [table]="'inventories'" 
                            [funs]="[]"
                            ></app-windowlistbuttons>
                        </div>
                        <!-- <div class="dropdown dropleft show" *ngIf="user.isSuperUser()">
                            <a class="btn btn-default btn-xs float-right mt-1 mr-1" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" title="Altre operazioni" aria-haspopup="true" aria-expanded="false">
                                <i class="fas fa-ellipsis-v"></i>
                            </a>
                            <div class="dropdown-menu py-0 bg-light" aria-labelledby="dropdownMenuLink">
                                <a type="button" class="btn btn-xs dropdown-item" (click)="openInventoryManager()">Gestione magazzini</a>
                            </div>
                        </div>
                        <button *ngIf="filter_status.value == '1'" (click)="trash()" [disabled]="form.value.id.length == 0" class="btn btn-danger btn-xs float-right ml-1 mt-1 mr-1"><i class="far fa-trash-alt"></i></button>
                        <button *ngIf="filter_status.value == '2'" (click)="delete()" [disabled]="form.value.id.length == 0" class="btn btn-danger btn-xs float-right ml-1 mt-1"><i class="fas fa-trash"></i></button>
                        <button *ngIf="filter_status.value == '2'" (click)="undo()" [disabled]="form.value.id.length == 0" class="btn btn-secondary btn-xs float-right ml-1 mt-1"><i class="fas fa-undo"></i></button>
                        <button  (click)="export()" class="btn btn-secondary btn-xs float-right ml-1 mt-1"><i class="fas fa-file-export"></i></button> -->

                        <!-- <button *ngIf="id_product==0"  (click)="load()"  class="btn btn-success btn-xs float-right m-1"><i class="fas fa-upload"></i> Carica</button> -->
                        <!-- <button *ngIf="id_product==0"  (click)="unload()"  class="btn btn-danger btn-xs float-right m-1"><i class="fas fa-download"></i> Scarica</button> -->
                        <!--<button class="btn btn-success btn-xs float-right m-1" (click)="fastout()"><i class="fas fa-plus"></i></button>-->
                    </div>
                </div>
                <div *ngIf="!list || list.length==0" class="m-5 text-center text-secondary">
                    <i class="icon fas fa-info-circle mr-2"></i><i>{{messageStatus}}</i>

                </div>
                <form *ngIf="list && list.length>0" [formGroup]="form">
                    <table class="table table-nowrap table-striped table-sm table-head-fixed m-0 text-sm">
                        <colgroup>
                            <col />
                            <col />
                            <col *ngFor="let i of inventories" [style.backgroundColor]="i.id_parent==0?i.color:transparent" />
                            </colgroup>
                        <thead class="bg-light">
                            <th class="text-center">
                                Qt
                                <button type="button" class="btn btn-tool" (click)="switchOrdering('ps.stock')">
                                    <i class="fas fa-sort {{classOrdering('ps.stock')}}"  ></i>
                                </button>
                            </th>
                            <th>
                                Prodotto
                                <button type="button" class="btn btn-tool" (click)="switchOrdering('pm.name')">
                                    <i class="fas fa-sort {{classOrdering('pm.name')}}"  ></i>
                                </button>
                                <span *ngIf="checkPermissions('manageInventories')" class="float-right pointer" title="Apri gestione magazzini" (click)="openInventoryManager()"><i class="fa fa-cog"></i></span>
                            </th>                            
                            <ng-container *ngFor="let i of inventories">
                                <th class="text-center" *ngIf="i.id_parent==0">
                                    <span class="help" title="{{i.name}}">{{i.code}}</span>
                                </th>
                            </ng-container>
                            <th class="d-none d-xl-table-cell"><span class="help" title="Ultimo aggiornamento">Aggiorn.</span>
                                <button type="button" class="p-0 btn btn-tool" (click)="switchOrdering('ps.date')">
                                    <i class="fas fa-sort {{classOrdering('ps.date')}}"  ></i>
                                </button>
                            </th>
                            <!--
                            <th>
                                Magazzino
                            </th>
                            <th >
                                Disponibilità
                                <button type="button" class="btn btn-tool" (click)="switchOrdering('ps.stock')">
                                    <i class="fas fa-sort {{classOrdering('ps.stock')}}"  ></i>
                                </button>
                            </th >
                            -->
                            <th></th>
                            <th>
                                <div #checkboxselectall class="float-right m-1 icheck-primary d-inline mr-1">
                                    <input  type="checkbox" title="Seleziona tutti"
                                    (change)="onChangeAll($event.target.checked)" />
                                </div>
                            </th>
                        </thead>
                        <tbody>

                            <!-- elemento ripetuto -->
                            <tr  *ngFor="let r of list">
                                <td [class.bg-success]="r.stock>0" [class.bg-light]="r.stock == 0" [class.bg-danger]="r.stock < 0" class="text-center pb-1">
                                    <i *ngIf="!r.icon" class="fas fa-box-open fa-2x"></i>
                                    <i class="{{r.icon}} fa-2x"></i><br>
                                    <b>{{r.stock}} <small>{{r.unit}}</small></b>
                                </td>
                                <td>
                                    <app-productitem [sn]="" [product]="r"></app-productitem>
                                </td>
                                <ng-container *ngFor="let s of r.stocks">
                                    <td class="border-left text-center" style="min-width:2rem;vertical-align: top;" *ngIf="s.id_parent==0"><br> 
                                        <b title="{{s.inventoryname}}" style="cursor: default" [style.color]="s.textcolor" [class.text-danger]="s.stock<0">{{s.stock}}</b>                                       
                                        <ng-container *ngIf="s.substocks">
                                            <ng-container *ngFor="let ss of s.substocks">
                                                <ng-container *ngIf="ss.stock!=0"><br>
                                                    <small title="{{ss.inventoryname}}" style="cursor: default" [style.color]="ss.textcolor" [class.text-danger]="ss.stock<0">({{ss.stock}})</small>
                                                </ng-container>                                                
                                            </ng-container>
                                        </ng-container>
                                        <ng-container *ngIf="s.stock<r.minstock"><br>
                                            <i title="Quantità inferiore allo stock minimo ({{r.minstock}} {{r.unit}})" class="text-warning fas fa-exclamation-triangle pt-2 help"></i>
                                        </ng-container>
                                    </td>
                                </ng-container>
                                <td class="border-left d-none d-xl-table-cell">
                                    <small>{{r.date | date:"dd/MM/yyyy"}}</small>
                                    <br><small class="text-gray">{{r.date | date:'EEEE'}}</small>
                                </td>
                                <!--
                                <td>{{ r.inventoryname}}</td>
                                <td style="min-width: 120px;" ><h5 [class.text-success]="r.avaible>0" [class.text-danger]="r.avaible < 1">{{r.avaible}} <small>{{r.unit}}</small></h5> </td>
                                    -->
                                <td>
                                    <button type="button" *ngIf="inventories && inventories.length>1" title="Sposta in un altro magazzino" class="btn btn-primary btn-xs float-right ml-1 mt-1" (click)="move(r)"><i class="fas fa-exchange-alt"></i></button>
                                    <button type="button" title="Azzera giacenze" class="btn btn-danger btn-xs float-right ml-1 mt-1" *ngIf="checkPermissions('resetStock')" (click)="resetStock(r)">&osol;</button>
                                </td>
                                <td>
                                    <input type="checkbox" #checkrecord id_record="{{r.id}}" title="{{r.id}}"
                                        (change)="onChange(r.id, $event.target.checked)" class="m-1 float-right" [checked]="checkedAll"
                                        />
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </form>
                <div class="card-footer clearfix py-1">
                    <app-pagination [model]="this" ></app-pagination>
                </div>
            </div>
        </div>
    </div>
</div>