<app-windowlist [model]="this">
    <div class="card mb-0">
        <div class="row">
            <div class="col-lg-5">
                <div class="input-group input-group-sm p-1">
                    <input type="text" #table_search name="table_search" class="form-control form-control-sm" placeholder="Cerca per nome o username ..." [(ngModel)]="filter_search.value" (keyup.enter)="getItems()">
                    <div class="input-group-append ">
                        <button type="submit" class="btn btn-default text-primary" title="Avvia ricerca" (click)="getItems()"><i class="fas fa-search"></i></button>
                        <button class="btn btn-default text-danger" title="Annulla ricerca" *ngIf="filter_search.value!=''" (click)="filter_search.value='';getItems();"><span id="searchclear" class="fas fa-times"></span></button>
                    </div>
                </div>
            </div>
            <div class="col-6 col-lg-2 p-1">
                <div class="input-group input-group-sm" >
                    <select class="form-control form-control-sm" [(ngModel)]="filter_role.value" (ngModelChange)="getItems()">
                        <option value="">Tutti i ruoli</option>
                        <option *ngFor="let ug of usergroups" [ngValue]="ug.name">{{ug.title}}</option>
                    </select>
                </div>
            </div>
            <div class="col-6 col-lg-2 p-1">
                <div class="input-group input-group-sm">
                    <select class="form-control form-control-sm"
                            [(ngModel)]="filter_status.value"
                            (ngModelChange)="getItems()" 
                            [class.text-orange]="filter_status.value=='0'" >
                        <option value="">Tutti</option>
                        <option value="1">Solo abilitati</option>
                        <option value="0">Solo disabilitati</option>
                    </select>
                </div>
            </div>
            <div class="col-lg-3">
                <div class="mr-1">
                    <app-windowlistbuttons 
                        [model]="this" 
                        [table]="'users'" 
                        ></app-windowlistbuttons>
                </div>                
            </div>
        </div>
        <form [formGroup]="form" *ngIf="list!=null">
            <table class="table table-sm table-hover text-nowrap m-0" >
                <thead>
                    <th>
                        <div #checkboxselectall class="icheck-primary d-inline mr-3">
                            <input type="checkbox" title="Seleziona tutti"
                            (change)="onChangeAll($event.target.checked);" />
                        </div>
                        ID
                    </th>
                    <th>Nome</th>
                    <th>Username</th>
                    <th>Info</th>
                    <th></th>
                </thead>
                <tbody>

                    <!-- elemento ripetuto -->
                    <ng-container *ngFor="let record of list">
                        <tr *ngIf="checkPermission(record)">
                            <td>
                                <div class="icheck-primary d-inline mr-3">
                                    <input type="checkbox" #checkrecord id_record="{{record.id}}"  title="{{record.id}}" 
                                    (change)="onChange(record.id, $event.target.checked);" [checked]="checkedAll"
                                    />
                                </div>
                                {{record.id}}
                            </td>
                            <td>
                                <i *ngIf="!record.enabled" title="Utente disabilitato" class="fas fa-user-slash mr-1 text-orange help"></i>
                                <ng-container *ngIf="record.enabled">
                                    <i *ngIf="record.role=='guest'" class="fas fa-user mr-1 text-gray"></i>                                
                                    <i *ngIf="record.role=='agent'|| record.role=='external'" class="fas fa-user-secret mr-1 text-pink"></i>
                                    <i *ngIf="record.role!='guest' && record.role!='agent' && record.role!='external'" class="fas fa-user-tie mr-1 text-primary"></i>
                                </ng-container>
                                {{record.name}}
                            </td>
                            <td>                               
                                {{record.username}}
                            </td>
                            <td>
                                <small>
                                    <i *ngIf="record.role=='admin'"       >Amministratore</i>
                                    <i *ngIf="record.role=='manager'"     >Manager</i>
                                    <i *ngIf="record.role=='agent'"       >Agente</i>
                                    <i *ngIf="record.role=='external'"    >Partner</i>
                                    <i *ngIf="record.role=='guest'"       >Ospite</i>
                                     dal {{record.created  | date:"d MMMM yyyy"}}
                                </small>
                            </td>
                            <td class="pr-2">
                                <button type="button" *ngIf="mode=='modal'" class="btn btn-secondary btn-xs float-right ml-1" (click)="selectRecord(record);">
                                    <span class="d-none d-sm-inline-block mr-2">Seleziona</span><i class="fas fa-chevron-right"></i></button>                                
                                <button type="button" *ngIf="currentUser.isSuperUser()" title="Elimina" class="btn btn-danger btn-xs float-right ml-1" (click)="confirm_delete(record.id)">
                                    <i class="fa fa-trash"></i></button>
                                <button type="button" *ngIf="mode!='modal'" (click)="openDetail(record)" title="Modifica / Vedi" class="btn btn-secondary btn-xs float-right ml-1">
                                    <i class="fas fa-pencil-alt"></i></button>
                            </td>
                        </tr>
                    </ng-container>

                </tbody>
            </table>
        </form>
        <div class="card-footer clearfix py-1">
            <app-pagination [model]="this" ></app-pagination>
        </div>
    </div>
</app-windowlist>