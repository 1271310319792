import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Globals } from 'projects/core/src/globals';
import { Model } from 'projects/core/src/include/model';
import { User } from 'projects/core/src/include/structures';
import { UserService } from 'projects/core/src/lib/user.service';
import { UserGroupService } from 'projects/core/src/lib/usergroup.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrl: './user.component.css'
})
export class UserComponent extends Model<User> implements OnInit,AfterViewInit {

  usergroups:any=[];

  constructor(
    private route1: ActivatedRoute,
    private userService: UserService,
    private usergroupsService:UserGroupService
  ){
    super(route1,userService); 
  }

  get currentUser(){
    return Globals.user;
  }

  ngOnInit (): void {

    this.title = 'Dettaglio utente';
    this.record=new User();
    super.ngOnInit();

    this.usergroupsService.getGroups().subscribe((result)=>{
      this.usergroups=result;
    }); 
    
    this.getItem();

   

  }



}
