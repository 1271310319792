    <ng-container *ngIf="product">
        <div class="modal-header">
            <span><i class="fas fa-exchange-alt mr-2"></i>{{product.brand}} <b>{{product.name}}</b></span>
            <div class="card-tools">
                <button type="button" class="btn btn-tool" (click)="cancel()" >
                    <i class="fas fa-times"></i>
                </button>
            </div>
        </div>
        <div class="modal-body">
            <div class="alert border my-2">
                <i class="fas fa-exclamation-circle text-info fa-2x mr-2"></i>Quantità complessiva di prodotto:&nbsp;<b [class.text-danger]="product.stock<0">{{product.stock}}</b>
            </div>
            <div *ngIf="not_assigned>0" class="alert border my-2">
                <i class="fas fa-exclamation-triangle text-danger fa-2x mr-2"></i>Quantità non assegnate:&nbsp;<b>{{not_assigned}}</b>
            </div>
            <div *ngIf="total>total_original" class="alert border my-2">
                <i class="fas fa-exclamation-triangle text-warning  fa-2x mr-2"></i>Quantità impostate non disponibili. Sarà richiesto di effettuare una <b>procedura di carico</b>
            </div>
            <div *ngIf="!stocks || stocks.length<2" class="alert border my-2">
                <i class="fas fa-exclamation-triangle text-warning fa-2x mr-2"></i>Non è possibile effettuare spostamenti
            </div>
            <table class="table-responsive border m-0" *ngIf="stocks && stocks.length>0">
                <thead>
                    <ng-container *ngFor="let s of stocks">
                        <th class="text-center p-1" style="vertical-align: middle;">
                            <span *ngIf="default_stock && s.id_inventory==default_stock.id_inventory" title="Magazzino di riferimento" class="help">
                                    {{s.inventoryname}}
                            </span>
                            <small *ngIf="!default_stock || s.id_inventory!=default_stock.id_inventory">{{s.inventoryname}}</small>
                            <small *ngIf="s.id_parent!=0" class="help" title="Sottomagazzino">*</small>
                        </th>
                    </ng-container>
                </thead>
                <thead *ngIf="product.has_serials">
                    <ng-container *ngFor="let s of stocks">
                        <th class="text-center p-1">
                            <span *ngIf="s.stock>0" class="text-lg font-weight-bold text-success">{{s.stock}}</span>
                            <span *ngIf="s.stock<=0" class="text-lg font-weight-bold text-danger">{{s.stock}}</span>                        
                        </th>
                    </ng-container>
                </thead>
                <tbody>
                    <tr>
                        <td *ngFor="let s of stocks" class="text-center p-1">
                            <ng-container *ngIf="product.has_serials">
                                <select style="width:7rem; height:30vh; overflow-y:scroll;" class="form-control m-auto p-0" multiple="true" name="selectedSerial" [(ngModel)]="selectedSerial" (ngModelChange)="selectSn(s,$event)">
                                    <ng-container *ngFor="let ser of s.serials" >
                                        <option [class.font-weight-bold]="ser.selected" [selected]="ser.selected" *ngIf="ser.avaible>0" [value]="ser.sn">
                                            {{ser.sn}}
                                        </option>
                                    </ng-container>
                                </select>
                                <a *ngIf="product.has_serials && selectedSerial.length>0" style="background:{{s.color}}" class="btn btn-outline-secondary btn-block btn-xs mt-1" (click)="moveSerials(s.id_inventory)">
                                    <i class="fa fa-arrow-up"></i><br>Sposta qui</a>
                            </ng-container>
                            <ng-container *ngIf="!product.has_serials">
                                <div class="border m-auto p-1" style="width:5rem; background:{{s.color}}">
                                    <ng-container *ngIf="!isSubInventory">
                                        <a class="btn btn-outline-success btn-block" (click)="deltaQuantity(s,1)"><i class="fa fa-plus"></i></a>
                                        <input min="0" step="1" class="form-control border-0 text-center text-xl px-1" style="background: transparent;" type="number" [(ngModel)]="s.stock" (change)="changeQuantity(s,$event.target.value)">
                                        <a class="btn btn-outline-danger btn-block"  (click)="deltaQuantity(s,-1)"><i class="fa fa-minus"></i></a>                                 
                                    </ng-container>
                                    <ng-container *ngIf="isSubInventory">
                                        <ng-container >
                                            <a class="btn btn-outline-success btn-block" *ngIf="s.id_parent>0" (click)="deltaQuantity(s,1)"><i class="fa fa-plus"></i></a>
                                            <input [readonly]="s.id_parent==0" min="0" step="1" style="background: transparent;" class="form-control border-0 text-center text-xl px-1" type="number" [(ngModel)]="s.stock" (change)="changeQuantity(s,$event.target.value)">
                                            <a class="btn btn-outline-danger btn-block" *ngIf="s.id_parent>0"  (click)="deltaQuantity(s,-1)"><i class="fa fa-minus"></i></a>                                     
                                        </ng-container>
                                    </ng-container>
                                    
                                </div>
                            </ng-container>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="row mt-2">
                <div class="col-lg-2 text-lg-right">
                    <label>Causale spostamento</label>
                </div>
                <div class="col-lg-10">
                    <select class="form-control form-control-sm" name="note" [(ngModel)]="note">
                        <option *ngFor="let c of causals" value="{{c}}">{{c}}</option>
                    </select>
                </div>
            </div>
        </div>


<!--
        <div class="modal-body">
            <div class="row">
                <div class="col-12">
                    
                    <table class="table table-responsive  table-sm table-head-fixed table-hover m-0 text-sm">
                        <colgroup>
                            <ng-container *ngFor="let s of stocks">
                                <col [class.bg-warning]="s.id_inventory==default_stock.id_inventory" />
                            </ng-container>
                        
                        </colgroup>
                        <thead class="bg-light">
                            <ng-container *ngFor="let s of stocks">
                                <th [class.bg-warning]="s.id_inventory==default_stock.id_inventory" class="text-center">
                                    <h6 style="min-width:5rem;width:5vw;">{{s.inventoryname}}</h6>
                                    <h4 *ngIf="product.has_serials">{{s.stock}}</h4>
                                    <button *ngIf="product.has_serials && selectedSerial.length>0" class="btn btn-primary btn-xs" (click)="moveSerials(s.id_inventory)">Sposta qui &nbsp;<i class="fa fa-arrow-down"></i></button>
                                </th>
                            </ng-container>
                        </thead>
                        <tbody >
                            <tr>
                                <td *ngFor="let s of stocks" >
                                        <ng-container  *ngIf="product.has_serials">
                                            <select style="height: 50vh;overflow-y: scroll;" class="form-control p-0" multiple="true" name="selectedSerial" [(ngModel)]="selectedSerial" (ngModelChange)="selectSn(s,$event)">
                                                <ng-container *ngFor="let ser of s.serials" >
                                                    <option  [selected]="ser.selected" *ngIf="ser.avaible>0" [value]="ser.sn">
                                                        {{ser.sn}}
                                                    </option>
                                                </ng-container>
                                            </select>
                                        
                                        
                                        </ng-container>
                                    
                                        <ng-container  *ngIf="!product.has_serials">
                                            <div >
                                                
                                                <button class="btn btn-success btn-block btn-default mt-1" (click)="deltaQuantity(s,1)"><i class="fa fa-plus"></i></button>
                                                <input min="0" step="1" class="form-control text-center text-xl" type="number" [(ngModel)]="s.stock" (change)="changeQuantity(s,$event.target.value)">
                                                <button class="btn btn-danger btn-block btn-default mb-1" (click)="deltaQuantity(s,-1)"><i class="fa fa-minus"></i></button>

                                            </div>
                                        </ng-container>
                                        
                                        
                                    
                                </td>
                            </tr>
                        </tbody>
                        
                    </table>

                    <div *ngIf="not_assigned>0" class="alert alert-danger mt-2">Quantità non assegnate {{not_assigned}}</div>
                    <div *ngIf="total>total_original" class="alert alert-warning mt-2">Quantità impostate non disponibili. Sarà effettuato una procedura di carico</div>
            
                </div>

                

            </div>
            <div class="row mt-2">
                <div class="col-2">
                    <label>Causale</label>
                </div>
                <div class="col-10">
                    <select class="form-control form-control-sm" name="note" [(ngModel)]="note">
                        <option *ngFor="let c of causals" value="{{c}}">{{c}}</option>
                    </select>
                </div>
            </div>


            <div class="alert alert-info alert-sm mt-2">
                Totale quantità del prodotto:&nbsp;<strong>{{product.stock}}</strong>
            </div>
        </div>
-->



        <div class="modal-footer text-right">
            <button class="btn btn-success" *ngIf="createDDT" (click)="confirmDDT()"><i class="fa fa-plus mr-2"></i>Crea DDT di spostamento</button>
            <button class="btn btn-success" *ngIf="!createDDT" (click)="confirm()"><i class="fa fa-check mr-2"></i>Conferma spostamenti</button>
            <button class="btn btn-secondary ml-2" (click)="cancel()">Annulla</button>
        </div>
</ng-container>