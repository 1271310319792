<app-windowlist [model]="this">
    <div class="row">
        <div class="col-lg-2 pr-lg-0" *ngIf="filterbox">
            <app-filtercontainer [title]="'Filtra per date'" >
                <app-filterdate [date_type]="100" [class]="'card p-1 mb-2'" [filter_date]="filter_date" (onSelect)="getItems()"></app-filterdate>
            </app-filtercontainer>
        </div>
        <div [class.col-lg-10]="filterbox" [class.col-lg-12]="!filterbox" >
            <div class="card elevation-2">
                <div class="p-0">
                    <div class="row" *ngIf="filterbox">
                    </div>
                    <div class="row" *ngIf="filterbox">
                        <div class="col-lg-6">
                            <div class="input-group input-group-info input-group-sm p-1">
                                <input type="text" #table_search name="table_search" class="form-control form-control-sm" placeholder="nome cliente o descrizione..." [(ngModel)]="this.filter_search.value" (keyup.enter)="updateList()">
                                <div class="input-group-append ">
                                    <button type="submit" class="btn btn-default text-primary"  title="avvia ricerca" (click)="updateList()"><i class="fas fa-search"></i></button>
                                    <button class="btn btn-default text-danger" *ngIf="filter_search.value!=''"  title="annulla ricerca" (click)="filter_search.value='';updateList();"><span id="searchclear" class="fas fa-times"></span></button>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 col-lg-3 py-1">
                            <select class="form-control form-control-sm p-1" [(ngModel)]="this.filter_phase.value" (change)="getItems()">
                                <option value="0">Tutte le fasi</option>
                                <option value="1">Preventivato</option>
                                <option value="2">In corso</option>
                                <option value="3">Concluso</option>
                                <option value="4">Annullato</option>
                            </select>
                        </div>
                        <div class="col-6 col-lg-3">
                            <div class="pr-1">
                                <app-windowlistbuttons 
                                [model]="this"  
                                [table]="'project'" 
                                [funs]="[]"
                                ></app-windowlistbuttons>
                            </div>
                            <!-- <button *ngIf="filter_status.value == '1'" (click)="trash()" [disabled]="form.value.id.length == 0" class="btn btn-danger btn-xs float-right mx-1 mt-1"><i class="far fa-trash-alt"></i></button>
                            <button *ngIf="filter_status.value == '2'" (click)="delete()" [disabled]="form.value.id.length == 0" class="btn btn-danger btn-xs float-right ml-1 mt-1"><i class="fas fa-trash"></i></button>
                            <button *ngIf="filter_status.value == '2'" (click)="undo()" [disabled]="form.value.id.length == 0" class="btn btn-secondary btn-xs float-right ml-1 mt-1"><i class="fas fa-undo"></i></button>
                            <button  (click)="export()" class="btn btn-secondary btn-xs float-right ml-1 mt-1"><i class="fas fa-file-export"></i></button>
                            <button (click)="openDetail(null)"  class="btn btn-success btn-xs float-right ml-1 mt-1"><i class="fas fa-plus"></i><span class="d-none d-sm-inline-block">&ensp;</span></button> -->
                        </div>
                    </div>

                    <div *ngIf="!list || list.length==0" class="m-5 text-center text-secondary">
                        <i class="icon fas fa-info-circle mr-2"></i><i>{{messageStatus}}</i>
    
                    </div>
                    <form *ngIf="list && list.length>0" [formGroup]="form">
                        <table class="table table-nowrap  table-sm table-head-fixed table-hover m-0 text-sm">
                            <thead class="bg-light">
                                <th width="20px">
                                        <input #checkboxselectall type="checkbox" title="Seleziona tutti"
                                        (change)="onChangeAll($event.target.checked)" />
                                </th>
                                <th class="d-none d-lg-table-cell">
                                    Data
                                    <button type="button" class="btn btn-tool" (click)="switchOrdering('i.date')">
                                        <i class="fas fa-sort {{classOrdering('a.date')}}"  ></i>
                                    </button>
                                </th>
                                <th>Titolo</th>
                                <th class="d-none d-lg-table-cell" *ngIf="id_address==0">
                                    Anagrafica
                                </th>
                                <th class="d-none d-xl-table-cell text-center">Fase</th>
                                <th class="text-right">Importo</th>
                                <th class="d-none d-xl-table-cell text-right">Costi</th>
                                <th class="d-none d-xl-table-cell text-right">Ricavi</th>
                                <th class="d-none d-lg-table-cell text-right">Rimanenza</th>
                                <th class="d-none d-xl-table-cell text-right">Margine</th>
                                <th class="d-none d-xl-table-cell text-right"></th>
                                <th width="60px"></th>
                            </thead>
                            <thead class="bg-white">
                                <tr>
                                    <td></td>
                                    <td class="d-none d-lg-table-cell"></td>
                                    <td><b>TOTALE</b></td>
                                    <td class="d-none d-lg-table-cell"></td>
                                    <td class="d-none d-xl-table-cell"></td>
                                    <td class="text-right">
                                        <b>{{total | currency:'EUR':true }}</b>
                                    </td>
                                    <td class="d-none d-xl-table-cell text-right">
                                        <b>{{total_cost | currency:'EUR':true }}</b>
                                    </td>
                                    <td class="d-none d-xl-table-cell text-right">
                                        <b>{{total_paid | currency:'EUR':true }}</b>
                                    </td>
                                    <td class="d-none d-lg-table-cell"></td>
                                    <td colspan="2" class="d-none d-xl-table-cell"></td>
                                    <td></td>
                                </tr>
                            </thead>
                            <tbody>

                                <!-- elemento ripetuto -->
                                <tr *ngFor="let record of list"
                                    [class.bg-yellowline]="record.phase==2"
                                    [class.bg-greenline]="record.phase==3"
                                    [class.bg-redline]="record.phase==4">
                                    <td title="GIALLO: in corso, VERDE: concluso, ROSSO: annullato">
                                        <input type="checkbox" #checkrecord id_record="{{record.id}}" title="{{record.id}}"
                                        (change)="onChange(record.id, $event.target.checked)" [checked]="checkedAll"
                                        />
                                    </td>
                                    <td class="d-none d-lg-table-cell" style="max-width:6rem">
                                        <span title="{{record.date | date:'EEEE'}}">
                                            {{record.date | date:'dd/MM/yy'}}
                                        </span>
                                    </td>
                                    <td [class.text-gray]="record.phase==1">
                                        <b>{{record.name}}</b>
                                        <ng-container *ngIf="record.note!=undefined && record.note!=''">
                                            <br><small class="d-inline-block overflow-hidden w-100 help" style="max-height:1.7rem;" title="{{record.note}}">
                                                <i class="fas fa-thumbtack text-info mr-2"></i>{{record.note}}</small>
                                        </ng-container>
                                    </td>
                                    <td class="d-none d-lg-table-cell" *ngIf="id_address==0" >
                                        <i class="fas fa-user text-gray mr-1"></i>
                                        <ng-container *ngIf="record.addressItem">{{ record.addressItem.name }}</ng-container><br/>
                                    </td>
                                    <td class="d-none d-xl-table-cell text-center">
                                        <span class="badge border" *ngIf="record.phase==1">preventivato</span>
                                        <span class="badge badge-warning" *ngIf="record.phase==2">in corso</span>
                                        <span class="badge badge-success" *ngIf="record.phase==3">concluso</span>
                                        <span class="badge badge-danger" *ngIf="record.phase==4">annullato</span>
                                    </td>
                                    <td class="text-right">
                                        {{record.amount | currency:"&euro;"}}
                                    </td>
                                    <td class="d-none d-xl-table-cell text-right">
                                        {{record.cost | currency:"&euro;"}}
                                    </td>
                                    <td class="d-none d-xl-table-cell text-right">
                                        {{record.paid | currency:"&euro;"}}
                                    </td>
                                    <td class="d-none d-lg-table-cell text-right">
                                        <span class="text-danger help" *ngIf="record.phase!=4 && (record.amount - record.paid > 0.01)" title="Differenza tra importo e ricavi">
                                            {{record.paid - record.amount | currency:"&euro;"}}
                                        </span>
                                        <span class="text-danger help" *ngIf="record.phase==4 && (record.cost - record.paid > 0.05)" title="Differenza tra costi e ricavi">
                                            {{record.paid - record.cost | currency:"&euro;"}}
                                        </span>
                                    </td>
                                    <td class="d-none d-xl-table-cell text-right">
                                        <span class="help" *ngIf="record.phase==2 || record.phase==3" title="Differenza tra costi e ricavi" 
                                            [class.text-success]="record.paid - record.cost > 0.05"
                                            [class.text-danger]="record.paid - record.cost < -0.05" >
                                            {{record.paid - record.cost | currency:"&euro;"}}
                                        </span>
                                    </td>
                                    <td class="d-none d-xl-table-cell text-right">
                                        <span class="help" *ngIf="record.phase==3" title="Margine/Importo" 
                                            [class.text-success]="record.paid - record.cost > 0.05"
                                            [class.text-danger]="record.paid - record.cost < -0.05" >
                                            ({{(record.paid - record.cost) / record.amount * 100 | number:'0.0'}}%)
                                        </span>                                        
                                    </td>
                                    <td>
                                        <button type="button" *ngIf="mode!='modal'"  (click)="openDetail(record)" routerLinkActive="active"  title="Modifica / Vedi" class="btn btn-secondary btn-xs float-right mr-1">
                                            <i class="fas fa-pencil-alt"></i><span class="d-none d-sm-inline-block">&ensp;</span></button>                                    
                                        <button type="button"  *ngIf="mode=='modal'" class="btn btn-secondary btn-xs float-right" (click)="selectRecord(record);">
                                            <span class="d-none d-sm-inline-block m-1">Seleziona</span><i class="fas fa-chevron-right"></i>
                                        </button>
                                    </td>
                                </tr>

                            </tbody>
                            <tfoot class="bg-white">
                                <tr>
                                    <td></td>
                                    <td class="d-none d-lg-table-cell"></td>
                                    <td><b>TOTALE</b></td>
                                    <td class="d-none d-lg-table-cell"></td>
                                    <td class="d-none d-xl-table-cell"></td>
                                    <td class="text-right">
                                        <b>{{total | currency:'EUR':true }}</b>
                                    </td>
                                    <td class="d-none d-xl-table-cell text-right">
                                        <b>{{total_cost | currency:'EUR':true }}</b>
                                    </td>
                                    <td class="d-none d-xl-table-cell text-right">
                                        <b>{{total_paid | currency:'EUR':true }}</b>
                                    </td>
                                    <td class="d-none d-lg-table-cell"></td>
                                    <td colspan="2" class="d-none d-xl-table-cell"></td>
                                    <td></td>
                                </tr>
                            </tfoot>
                        </table>
                    </form>
                </div>
                <div class="card-footer clearfix py-1">
                    <app-pagination [model]="this" ></app-pagination>
                </div>
            </div>
        </div>
    </div>
</app-windowlist>