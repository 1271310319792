import { Component, OnInit,Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { ModelList } from '../../include/modellist'
import { UntypedFormBuilder, FormGroup, FormArray, FormControl } from '@angular/forms';
import { UserService } from '../../lib/user.service';
import { Filter, FilterMode, User } from '../../include/structures';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Globals } from '../../globals';
import { UserComponent } from './list/detail/user.component';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.sass']
})

export class UsersComponent implements OnInit {
  @Input()
  header=true;

  tab=1;
  
  
  ngOnInit(): void {
    
  }

  

 
}
