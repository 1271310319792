import { Component, OnInit } from '@angular/core';
import { Modal } from 'projects/core/src/include/modal';
import { Inventories } from '../../../db/inventories';
import { InventoriesService } from '../../../services/inventories.service';
import { Globals } from 'projects/core/src/globals';
import { User } from 'projects/core/src/include/structures'


@Component({
  selector: 'app-listinventories',
  templateUrl: './listinventories.component.html',
  styleUrls: ['./listinventories.component.sass']
})

export class ListinventoriesComponent extends Modal implements OnInit {

  get user(): User {
    return Globals.user;
}

  constructor(private inventoriesService:InventoriesService) {super() }
  list:Inventories[];
  

  ngOnInit(): void {
    this.getItems();
  }


  getItems(){
    this.inventoriesService.getInventories().subscribe((items)=>{
      this.list=items;
    });
  }


  addInventory():void{
    let name=prompt("Inserisci il nome del magazzino");
    if(name!="" && name!=undefined){
      let r:Inventories={} as Inventories;
      r.name=name;
      this.inventoriesService.save(r,()=>{
        this.getItems();
      });
      
    }
  }


  editInventory(record:Inventories){
    let newName:string;
    newName=prompt("Nome del magazzino",record.name);
    if(newName!=record.name){
      record.name=newName;
      this.inventoriesService.save(record,(id)=>{
        this.getItems();
      });
    }
  }


  deleteInventory(record:Inventories){
    Globals.modal.showConfirm("","Confermi di voler eliminare il magazzino "+record.name+" e tutte le voci associate?",()=>{  
      this.inventoriesService.delete([record.id.toString()]).subscribe(()=>{
        this.getItems();
      });
    },"Sì. Elimina","No. Annulla","danger");
  }


 update(r){
    this.inventoriesService.updateAny(r,[r.id],()=>{
    });
  }

}
