import { Component, OnInit } from '@angular/core';
import { Globals } from 'projects/core/src/globals';
import { Extension } from 'projects/core/src/include/parameters';
import { CSettingWindow, ISettingWindow } from 'projects/core/src/include/settingwindow';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrl: './settings.component.css'
})

@ISettingWindow.register

export class DocumentsSettingsComponent extends CSettingWindow implements OnInit {
  
  tab='1';
  module:Extension;

  banks:any=[];
  causals:any=[];
  sectionals:any=[];


  ngOnInit(): void {
    this.module=Globals.parameters.get("documents");
    this.banks=this.module.getParam("banks",[]);
    this.causals=this.module.getParam("causals",[]);
    this.sectionals=this.module.getParam("sectionals",[]);

  }
   


}
