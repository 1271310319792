declare interface Date 
    {
        toMysqlDate: () => string;
        toMysqlDateTime: () => string;
        
    }


    Date.prototype.toMysqlDate = function() 
    {

        const d=pad(this.getFullYear(),4).toString()+"-"+pad((this.getMonth()+1),2).toString()+"-"+pad(this.getDate(),2).toString();

        return d;//your calculations goes here
    };

    Date.prototype.toMysqlDateTime = function() 
    {

        const d=pad(this.getFullYear(),4).toString()+"-"+pad((this.getMonth()+1),2).toString()+"-"+pad(this.getDate(),2).toString()+" "+pad(this.getHours(),2).toString()+":"+pad(this.getMinutes(),2).toString()+":"+pad(this.getSeconds(),2).toString();

        return d;//your calculations goes here
    };
    
    function pad(num:number, size:number): string {
        let s = num+"";
        while (s.length < size) s = "0" + s;
        return s;
    }
    