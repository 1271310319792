<app-windowlist [model]="this">
    <div class="card">
        <div class="row">
            <div class="col-md-8">
                <div class="input-group input-group-sm p-1">
                    <input type="text" #table_search name="table_search" class="form-control form-control-sm" placeholder="Cerca per nome ..." [(ngModel)]="filter_search.value" (keyup.enter)="getItems()">
                    <div class="input-group-append ">
                        <button type="submit" class="btn btn-default text-primary"  title="Avvia ricerca" (click)="getItems()"><i class="fas fa-search"></i></button>
                        <button class="btn btn-default text-danger"  title="Annulla ricerca" *ngIf="filter_search.value!=''" (click)="filter_search.value='';getItems();"><span id="searchclear" class="fas fa-times"></span></button>
                    </div>
                </div>
            </div>
            
            <div class="col-lg-4">
                <button class="btn btn-success btn-sm float-right m-1" data-toggle="modal" data-target="#modalRule" (click)="addType()"><i class="fa fa-plus"></i></button>
            </div>
        </div>
        <div class="card-body p-0">
          <form>
            <table class="table table-sm table-sriped text-nowrap m-0" >
                <thead>
                    <th>ID</th>
                    <th>Descrizione</th>
                    <th>IVA</th>
                    <th>Codice</th>
                    <th></th>
                </thead>
                <tbody>

                    <!-- elemento ripetuto -->
                    <tr *ngFor="let tax of list">
                        <td>{{tax.id}}</td>
                        <td class="pl-2">{{tax.label}}</td>
                        <td class="pl-2">{{tax.tax}}</td>
                        <td class="pl-2">{{tax.code}}</td>
                        <td class="pr-2">
                            <a type="button"  *ngIf="mode=='modal'" class="btn btn-secondary btn-sm" (click)="selectRecord(tax);">
                                <span class="d-none d-sm-inline-block m-1">Seleziona</span><i class="fas fa-chevron-right"></i>
                            </a>
                            <button   *ngIf="mode!='modal'" type="button"  title="Elimina" class="btn btn-danger btn-xs float-right ml-1"  (click)="confirm_delete(tax.id)">
                                <i class="fas fa-trash"></i><span class="d-none d-sm-inline-block">&ensp;</span></button>
                            <button   *ngIf="mode!='modal'" type="button"  title="Duplica" class="btn btn-info btn-xs float-right ml-1" (click)="duplicate(tax)">
                                <i class="far fa-clone"></i><span class="d-none d-sm-inline-block">&ensp;</span></button> 
                            <button   *ngIf="mode!='modal'" type="button"  title="Modifica" class="btn btn-secondary btn-xs float-right ml-1"  data-toggle="modal" data-target="#modalRule" (click)="setRule(tax)">
                                <i class="fas fa-pencil-alt"></i><span class="d-none d-sm-inline-block">&ensp;</span></button>
                        </td>
                    </tr>

                </tbody>
            </table>
          </form>
        </div>
        <div class="card-footer clearfix py-1">
            <app-pagination [model]="this" ></app-pagination>
        </div>
    </div>
</app-windowlist>

<div class="modal fade" id="modalRule" tabindex="-1" role="dialog" >
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <b>Dettaglio IVA</b>
            </div>
            <div class="modal-body" *ngIf="selectedTax">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="form-group form-group-sm">
                            <label>Etichetta</label>
                            <input class="form-control form-control-sm" type="text" name="label" [(ngModel)]="selectedTax.label"/>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group form-group-sm">
                            <label>Percentuale</label>
                            <input class="form-control form-control-sm" type="number" name="tax" [(ngModel)]="selectedTax.tax"/>
               
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="form-group form-group-sm">
                            <label>Codice IVA (in fatturazione elettronica)</label>
                            <input class="form-control form-control-sm" type="text" name="code" [(ngModel)]="selectedTax.code"/>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="form-group form-group-sm">
                            <label>Codice Natura (in fatturazione elettronica)</label>
                            <input class="form-control form-control-sm" type="text" name="nature" [(ngModel)]="selectedTax.nature"/>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-success" data-dismiss="modal" (click)="confirmRule()"><i class="fas fa-save mr-1"></i> Conferma</button>
                <button type="button" class="btn btn-secondary" data-dismiss="modal"  >Annulla</button>
            </div>
        </div>
    </div>
</div>
