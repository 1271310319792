import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { Globals } from '../globals';
import { WSService } from '../include/service';

@Injectable({
  providedIn: 'root'
})
export class CustomfunctionsService extends WSService<any> {


  static customfunctions=[];
  constructor() { super("customfunctions") };

  getFunctions(table):Observable<any>{

    let customfunctions;
    for(let t of CustomfunctionsService.customfunctions ){
      if(t.table==table){
        customfunctions=t.customfunctions;
      }
    }

    if(customfunctions==undefined){
      Globals.ws.requestUrl("customfunctions","getItems",["c.table='"+table+"'"]).subscribe((items:any)=>{
        
        let found=false;
        for(let i=0;i<CustomfunctionsService.customfunctions.length;i++ ){
          if(CustomfunctionsService.customfunctions[i].table==table){
            CustomfunctionsService.customfunctions[i].customfunctions=items;
            found=true;
          }
        }

        if(!found){
          let t:any={};
          t.table=table;
          t.customfunctions=items;
          CustomfunctionsService.customfunctions.push(t);

        }
        
        
      });
      return Globals.ws.requestUrl("customfunctions","getItems",["c.table='"+table+"'"]);

    }else{

      function sequenceSubscriber(observer: Observer<any[]>) {

        for(let i=0;i<CustomfunctionsService.customfunctions.length;i++ ){
          if(CustomfunctionsService.customfunctions[i].table==table){
            observer.next(CustomfunctionsService.customfunctions[i].customfunctions);
          }
        }

       
        observer.complete();
        return {unsubscribe() {}};
      }
      
        return new Observable(sequenceSubscriber);
    }

    //return Globals.ws.requestUrl("customfunctions","getItems",["c.table='"+table+"'"]);
  }

  executeFunction(id,params=null):Observable<any>{
    return Globals.ws.requestUrl("customfunctions","executeFunction",[id,params]);
  }

  

}