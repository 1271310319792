<app-windowlist [model]="this">
    <div *ngIf="mode!='embedded'">
        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item">
                <a class="nav-link" [class.active]="viewMode=='calendar'" (click)="viewMode='calendar'">
                    <i class="fas fa-calendar-alt"></i><span class="d-none d-sm-inline-block ml-2">Calendario</span></a>
            </li>
            <li class="nav-item">
                <a class="nav-link" [class.active]="viewMode=='table'" (click)="viewMode='table'">
                    <i class="fas fa-list"></i><span class="d-none d-sm-inline-block ml-2">Lista</span></a>
            </li>
        </ul>
    </div>
    <div class="tab-content">
        <div class="row">          
            <div class="col-lg-2 pr-lg-0" *ngIf="filter_box && viewMode=='table'">
              <app-filtercontainer [title]="'Filtra per date o pagamenti'" >
                    <app-filterdate [date_type]="default_filterdate" [date_from]="date_from" [date_to]="date_to" [future]="true" [class]="'card p-1'" [filter_date]="filter_date" (onSelect)="updateList()"></app-filterdate>
                    <ul class="card small-box list-group p-1 mt-2">
                        <li class="list-group-item pointer" [class.active]="filter_id_type.value==''" (click)="filter_id_type.value='';getItems()">Tutte le tipologie</li>
                        <li class="list-group-item pointer" [class.active]="filter_id_type.value==t.id" *ngFor="let t of type_deadlines" (click)="filter_id_type.value=t.id;getItems()">{{t.name}}</li>
                        <div class="icon"><i class="far fa-calendar-check"></i></div>
                    </ul>
                    <ul class="card small-box list-group p-1 my-2" *ngIf="methodpayments.length>0 && isModuleEnabled('cash-flow')" >
                        <li class="list-group-item text-truncate pointer" [class.active]="filter_method_payment.value==''" (click)="filter_method_payment.value='';getItems()">Tutte le modalità di pagamento</li>
                        <li class="list-group-item text-truncate pointer" [class.active]="filter_method_payment.value && filter_method_payment.value.indexOf(m.name)>-1" *ngFor="let m of methodpayments"  (click)="setFilterTypeValue('filter_method_payment',m.name);">{{m.name}}</li>
                        <div class="icon"><i class="fas fa-dollar-sign"></i></div>
                    </ul>
              </app-filtercontainer>
            </div>
            <div [class.col-lg-12]="!filter_box || viewMode=='calendar'" [class.col-lg-10]="filter_box">
                <div class="card mb-0">
                    <div class="p-0">
                        <div class="row" *ngIf="viewMode!='calendar'">
                            <div class="col-5" >
                                <ng-container *ngIf="filter_box">
                                    <div class="input-group input-group-sm p-1" >
                                        <input type="text" #table_search name="table_search" class="form-control float-right" placeholder="cerca..." [(ngModel)]="filter_search.value" (keyup.enter)="getItems()">
                                        <div class="input-group-append ">
                                            <button type="submit" class="btn btn-default text-primary"  title="Avvia ricerca" (click)="getItems()"><i class="fas fa-search"></i></button>
                                            <button class="btn btn-default text-danger"  title="Annulla ricerca" *ngIf="filter_search.value!=''" (click)="filter_search.value='';getItems();"><span id="searchclear" class="fas fa-times"></span></button>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                            <div [class.col-7]="filter_box" [class.col-12]="!filter_box" class="pb-1" >
                                
                                <app-windowlistbuttons 
                                [model]="this" 
                                [table]="'deadlines'" 
                                [openDetailExtra]="[{'name':'id_address','value':id_address},{'name':'id_table','value':id_table},{'name':'table','value':table},{'name':'reference','value':reference},{'name':'amount','value':amount},{'name':'tax','value':tax},{'name':'methodpayment','value':methodpayment},{'name':'date','value':date}]" 
                                [funs]="[
                                    {'name':'Altri comandi','value':''},
                                    {'name':'Assegna conto contabile','value':'assignFinancialAccount','role':'Admin','module':'financialaccounts'},                                
                                    {'name':'Imposta come movimento di uscita','value':'setTypeAmountOut'},
                                    {'name':'Imposta come movimento di entrata','value':'setTypeAmountIn'}                                   
                                ]"
                                ></app-windowlistbuttons>                                
                                <!-- <button type="button"  title="Elimina" *ngIf="filter_status.value == '1'" (click)="trash()" [disabled]="form.value.id.length == 0" class="btn btn-danger btn-xs float-right ml-1 "><i class="far fa-trash-alt"></i></button>
                                <button type="button"  title="Elimina" *ngIf="filter_status.value == '2'" (click)="delete()" [disabled]="form.value.id.length == 0" class="btn btn-danger btn-xs float-right ml-1 "><i class="fas fa-trash"></i></button>
                                <button type="button"  title="Ripristina" *ngIf="filter_status.value == '2'" (click)="undo()" [disabled]="form.value.id.length == 0" class="btn btn-secondary btn-xs float-right ml-1 "><i class="fas fa-undo"></i></button>
                                
                                <button type="button"  title="Esporta" *ngIf="toolbar_action" (click)="export()" class="btn btn-secondary btn-xs float-right ml-1 "><i class="fas fa-file-export"></i></button>
                                <button type="button"  title="Aggiungi" *ngIf="toolbar_action" class="btn btn-success btn-xs float-right ml-1" 
                                        (click)="openDetail(null,[{'name':'id_address','value':id_address},{'name':'id_table','value':id_table},{'name':'table','value':table},{'name':'reference','value':reference},{'name':'amount','value':amount},{'name':'tax','value':tax},{'name':'methodpayment','value':methodpayment},{'name':'date','value':date}],null,'xl')" 
                                        ><i class="fas fa-plus"></i></button> -->                                
                                <button type="button" title="Crea scadenze multiple" *ngIf="toolbar_action" (click)="openGenerateInstallments()" class="btn btn-success btn-xs text-yellow float-right mt-1 ml-1 "><i class="fas fa-hat-wizard"></i></button>
                                <button type="button" title="Converte le scadenze selezionate in attività" (click)="convertDeadlinesIntoActivities()" [disabled]="form.value.id.length == 0" class="btn btn-primary btn-xs float-right ml-1 mt-1"><i class="far fa-calendar-check"></i></button>
                            </div>

                        </div>
                        <!--<app-deadlines-calendar *ngIf="filter_box && !no_dateend" [list]="list" (periodSelected)="filter_date.value=$event.date_from;filter_date.value2=$event.date_to;updateList()"></app-deadlines-calendar>-->
                        <app-deadlines-calendar *ngIf="viewMode=='calendar'" [table]="table" [id_table]="id_table" [reference]="reference"></app-deadlines-calendar>
                        <app-deadlines-list *ngIf="viewMode=='table'"  [list]="list" [viewMode]="viewMode" (updateList)="updateList()" [form]="form"></app-deadlines-list>

                    </div>
                    <div class="card-footer clearfix py-1" *ngIf="showFooter && viewMode!='calendar'" >
                        <app-pagination  [model]="this" ></app-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <ng-template #generateInstallmentsModal let-modal>
        <div class="modal-header">
            <b>Crea scadenze multiple</b>
            <div class="card-tools">
                <button type="button" class="btn btn-tool" (click)="closeGenerateInstallments()" >
                  <i class="fas fa-times"></i>
                </button>
            </div>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-sm-6">
                    <label>Dal</label>
                    <div class="input-group" >
                        <input class="form-control form-control-sm" type="date"  name="start"  [(ngModel)]="generate.start"  />
                    </div>                        
                </div>
                <div class="col-sm-6">
                    <label>Al</label>
                    <div class="input-group" >
                        <input class="form-control form-control-sm" type="date"  name="end"  [(ngModel)]="generate.end"  />
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <label>Tipologia di scadenza</label>
                    <select class="form-control form-control-sm" [(ngModel)]="generate.id_type" name="id_type">
                        <option *ngFor="let t of type_deadlines" value="{{t.id}}">{{t.name}}</option>
                    </select>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <label>Descrizione</label>
                    <input class="form-control form-control-sm" [(ngModel)]="generate.description" name="description">
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <label>Elemento collegato</label>
                    <div class="border p-2 mb-2">
                        <app-reference #referenceRecord [showConnectBtn]="true" (recordConnected)="setReferenceToGenerate($event)" [reference]="generate.reference" [table]="generate.table" [mode]="'detail'"></app-reference>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-2"><label>Importo</label></div>
                <div class="col-10">
                    <div class="input-group input-group-sm mb-1">
                        <input type="number" min="0" step="0.01" name="amount" class="form-control form-control-sm" [(ngModel)]="generate.amount" (change)="calculateGrossGenerate()" />
                        <div class="input-group-prepend input-group-prepend-sm">
                            <span class="input-group-text">&euro;</span>
                        </div>
                    </div>
                </div>
                <div class="col-2"><label>IVA</label></div>
                <div class="col-10">
                    <div class="input-group input-group-sm mb-1">
                        <input type="number" min="0" step="1" name="tax" class="form-control form-control-sm" [(ngModel)]="generate.tax"  (change)="calculateGrossGenerate()" />
                        <div class="input-group-prepend input-group-prepend-sm">
                            <span class="input-group-text">%</span>
                        </div>
                    </div>
                </div>
                <div class="col-2"><label>Totale</label></div>
                <div class="col-10">
                    <div class="input-group input-group-sm">
                        <input type="number" min="0" step="1" name="gross" class="form-control form-control-sm" [(ngModel)]="generate.gross"  (change)="calculateNetGenerate()" />
                        <div class="input-group-prepend input-group-prepend-sm">
                            <span class="input-group-text">&euro;</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <label>Tipologia</label>
                    <div class="btn-group border px-0 col-12" role="group">
                        <button type="button" class="btn btn-sm " [class.btn-secondary]="tab==0" (click)="tab=0;generate.type=1;generate.amount_type=30;">Pianifica</button>
                        <button type="button" class="btn btn-sm" [class.btn-secondary]="tab==1" (click)="tab=1;generate.type=0;generate.amount_type=1;">Rateizza</button>
                    </div>
                </div>
            </div> 
            <!-- PIANIFICA INCASSO -->
            <div class="row" *ngIf="tab==0">
                <div class="col-12">
                    <label>Ripeti importo</label>
                    <div class="input-group">
                        <select class="form-control"  name="typedetail"  [(ngModel)]="generate.typedetail" >
                            <option value="1">ogni giorno</option>
                            <option value="7">ogni 7 giorni</option>
                            <option value="14">ogni 14 giorni</option>
                            <option value="30">ogni 30 giorni</option>
                            <option value="60">ogni 60 giorni</option>
                            <option value="90" >ogni 90 giorni</option>
                            <option value="180" >ogni 180 giorni</option>
                            <option value="365" >ogni anno</option>
                            <option value="-1" >al giorno del mese (da specificare)</option>
                            <option value="-2" >al giorno di ogni bimestre (da specificare)</option>
                            <option value="-3" >al giorno di ogni trimestre (da specificare)</option>
                            <option value="-4" >al giorno di ogni quadrimestre (da specificare)</option>
                            <option value="-5" >al giorno di ogni semestre (da specificare)</option>
                            <!--<option value="-2" >al giorno della settimana (da specificare)</option>-->
                        </select>
                    </div>
                    <ng-container *ngIf="generate.typedetail=='-1' || generate.typedetail=='-2' || generate.typedetail=='-3' || generate.typedetail=='-4' || generate.typedetail=='-5'">
                        <label>Giorno del mese</label>
                        <div class="input-group">
                            <input type="number" value="1" step="1" min="1" max="31" class="form-control form-control-sm" name="day_of_month" [(ngModel)]="generate.params" >
                        </div>                        
                    </ng-container>
                    <small class="text-gray mt-1">
                        <i class="fa fa-info-circle mr-1"></i>
                        <i *ngIf="generate.typedetail>0">Crea una rata da <b>{{generate.gross | currency:" &euro; "}}</b> ogni <b>{{generate.typedetail}}</b> giorni.</i>
                        <i *ngIf="generate.typedetail==-1">Crea una rata da <b>{{generate.gross | currency:" &euro; "}}</b> ogni giorno <b>{{generate.params}}</b> del mese.</i>
                        <i *ngIf="generate.typedetail==-2">Crea una rata da <b>{{generate.gross | currency:" &euro; "}}</b> ogni giorno <b>{{generate.params}}</b> del bimestre.</i>
                        <i *ngIf="generate.typedetail==-3">Crea una rata da <b>{{generate.gross | currency:" &euro; "}}</b> ogni giorno <b>{{generate.params}}</b> del trimestre.</i>
                        <i *ngIf="generate.typedetail==-4">Crea una rata da <b>{{generate.gross | currency:" &euro; "}}</b> ogni giorno <b>{{generate.params}}</b> del quadrimestre.</i>
                        <i *ngIf="generate.typedetail==-5">Crea una rata da <b>{{generate.gross | currency:" &euro; "}}</b> ogni giorno <b>{{generate.params}}</b> del semestre.</i>
                    </small>
                </div>
            </div>            
            <!-- RATEIZZA -->
            <div class="row" *ngIf="tab==1">
                <div class="col-12">
                    <label>Dividi importo</label>
                    <div class="input-group">                        
                        <select class="form-control"  name="typedetail"  [(ngModel)]="generate.typedetail" >
                            <option value="7" >ogni 7 giorni</option>
                            <option value="15" >ogni 15 giorni</option>
                            <option value="30" >ogni 30 giorni</option>
                            <option value="60" >ogni 60 giorni</option>
                            <option value="90" >ogni 90 giorni</option>
                            <option value="180" >ogni 180 giorni</option>
                            <option value="360" >ogni anno</option>
                            <option value="720" >ogni 2 anni</option>
                        </select>
                    </div>
                    <small class="text-gray mt-1">
                        <i class="fa fa-info-circle mr-1"></i>
                        <i>Suddivide l'importo di <b>{{generate.gross | currency:" &euro; "}}</b> in rate da pagare ogni <b>{{generate.typedetail}}</b> giorni</i>
                    </small>
                </div>                
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-success"  [disabled]="generate.id_type==0  || generate.id_address==0  || generate.description==''" (click)="generateInstallments()"><i class="fas fa-plus-square mr-2"></i>Crea scadenze</button>
            <button type="button" class="btn btn-secondary" (click)="closeGenerateInstallments()">Annulla</button>
        </div>
    </ng-template>


    <ng-template #convertActivityModal let-modal>
        <div class="modal-header">
            <b class="modal-title">Converti scadenze <span *ngIf="convert['deadline']">di <strong>{{convert['deadline']['type']}}</strong></span> in attività</b>
            <div class="card-tools">
                <button type="button" class="btn btn-tool" (click)="closeConvertActivity()" >
                    <i class="fas fa-times"></i>
                </button>
            </div>
        </div>
        <div class="modal-body">
                <div class="row">
                    <div class="col-sm-4" *ngIf="convertActivityMode==0">
                        <label>Data</label>
                        <div class="input-group">
                            <input class="form-control form-control-sm" type="date"  name="date"  [(ngModel)]="convert.date"  />
                        </div>
                    </div>
                    <div class="col-sm-4" [class.col-sm-8]="convertActivityMod!=0">
                        <label>Tipologia</label>
                        <select required class="form-control form-control-sm" [(ngModel)]="convert.id_type" name="id_type" #id_type="ngModel" required>
                            <option *ngFor="let t of type_activities" [ngValue]="t.id">{{t.name}}</option>
                        </select>
                    </div>
                    <div class="col-sm-4">
                        <label>Operatore</label>
                        <select class="form-control form-control-sm" [(ngModel)]="convert.id_user" name="id_user" >
                            <ng-container *ngFor="let u of users">
                                <option *ngIf="u.enabled" [ngValue]="u.id">{{u.username}}</option>
                            </ng-container>
                        </select>
                    </div>
                    <div class="col-sm-12" *ngIf="convertActivityMode==0">
                        <label>Descrizione</label>
                        <textarea class="form-control" rows="2" onblur="this.rows=2;" onfocus="this.rows=4;"  name="description" [(ngModel)]="convert.description"></textarea>
                    </div>
                    <div class="col-sm-4" *ngIf="convertActivityMode==0">
                        <label>Importo</label>
                        <div class="input-group">
                            <div class="input-group-prepend input-group-prepend-sm">
                                <span class="input-group-text">&euro;</span>
                            </div>
                            <input type="number" class="form-control form-control-sm"  min="0" step="0.01" name="amount" #amount="ngModel" placeholder="0" [(ngModel)]="convert.amount" >
                        </div>
                    </div>
                    <div class="col-sm-4" *ngIf="convertActivityMode==0">
                        <label>IVA</label>
                        <div class="input-group input-group-prepend-sm">
                            <div class="input-group-prepend ">
                                <span class="input-group-text">%</span>
                            </div>
                            <input type="number" class="form-control form-control-sm" min="0" step="1" name="tax" placeholder="..." [(ngModel)]="convert.tax" >
                        </div>
                    </div>
                    <div class="col-sm-4" *ngIf="convertActivityMode==0">
                        <label>Totale</label>
                        <div class="input-group">
                            <div class="input-group-prepend input-group-prepend-sm">
                                <span class="input-group-text">&euro;</span>
                            </div>
                            <input type="number" class="form-control form-control-sm" min="0" step="0.01" name="gross" placeholder="..." [(ngModel)]="convert.gross" >
                        </div>
                    </div>
                    <div class="col-sm-12">
                        <label>Note</label>
                        <textarea class="form-control form-control-sm" rows="2" onblur="this.rows=2;" onfocus="this.rows=4;"  name="note" [(ngModel)]="convert.note"></textarea>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
            <button type="button" class="btn btn-success"  [disabled]="convert.id_type == null || convert.date == ''" (click)="confirmActivity()"><i class="fas fa-sync-alt mr-2"></i>Converti</button>
            <button type="button" class="btn btn-secondary" (click)="closeConvertActivity()">Annulla</button>
            </div>
    </ng-template>
</app-windowlist>