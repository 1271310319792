<app-window [title]="record.name" [model]="this">

        <form role="form" id="detailForm">
            <ul class="nav nav-tabs" id="tabShop" role="tablist">
                <li class="nav-item">
                    <a class="nav-link active" id="tab2-nav" data-toggle="tab" href="#tabShop2" role="tab" aria-controls="tab2-tab" aria-selected="false">
                        <i class="fas fa-clipboard"></i><span class="d-none d-sm-inline-block ml-2">Generale</span>
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" id="tab3-nav" data-toggle="tab" href="#tabShop3" role="tab" aria-controls="tab3-tab" aria-selected="false">
                        <i class="fas fa-info"></i><span class="d-none d-sm-inline-block ml-2">Informazioni</span>
                    </a>
                </li>
                <li class="nav-item" *ngIf="id != 0">
                    <a class="nav-link" id="tab4-nav" data-toggle="tab" href="#tabShop4" role="tab" aria-controls="tab4-tab" aria-selected="true">
                        <i class="nav-icon fas fa-cash-register"></i><span class="d-none d-sm-inline-block ml-2">Prodotti</span>
                    </a>
                </li>
                <li class="nav-item" *ngIf="id != 0">
                    <a class="nav-link  " id="tab5-nav" data-toggle="tab" href="#tabShop5" role="tab" aria-controls="tab5-tab" aria-selected="true">
                        <i class="nav-icon fas fa-file-signature"></i><span class="d-none d-sm-inline-block ml-2">Contratti</span>
                    </a>
                </li>
                <li class="nav-item" *ngIf="id != 0">
                    <a class="nav-link  " id="tab6-nav" data-toggle="tab" href="#tabShop6" role="tab" aria-controls="tab6-tab" aria-selected="true">
                        <i class="nav-icon far fa-calendar-alt"></i><span class="d-none d-sm-inline-block ml-2">Agenda</span>
                    </a>
                </li>
                <li class="nav-item" *ngIf="id != 0">
                    <a class="nav-link  " id="tab7-nav" data-toggle="tab" href="#tabShop7" role="tab" aria-controls="tab7-tab" aria-selected="true">
                        <i class="nav-icon fas fa-tasks"></i><span class="d-none d-sm-inline-block ml-2">Attività</span>
                    </a>
                </li>
                <li class="nav-item" *ngIf="id != 0">
                    <a class="nav-link  " id="tab8-nav" data-toggle="tab" href="#tabShop8" role="tab" aria-controls="tab8-tab" aria-selected="true">
                        <i class="fas fa-code"></i><span class="d-none d-sm-inline-block ml-2">Avanzate</span>
                    </a>
                </li>
            </ul>
            
            <div class="p-2" >
                <div class="tab-content" id="tabShopContent">
                    <!-- INDIRIZZO -->
                    <div class="tab-pane fade show active" id="tabShop2" role="tabpanel" aria-labelledby="tab2-tab">
                        <div class="row">
                            <div class="col-lg-2"><label>Denominazione</label></div>
                            <div class="col-lg-10">
                                    <input autofocus required type="text" class="form-control form-control-sm" name="name" placeholder="..." #name="ngModel" [(ngModel)]="record.name">
                                    <div *ngIf="name.invalid && (name.dirty || name.touched)" class="alert alert-danger">
                                        <div *ngIf="name.errors.required">Campo obbligatorio.</div>
                                    </div>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-lg-2"><label>Attività</label></div>
                            <div class="col-lg-4">
                                <input type="text" class="form-control form-control-sm" placeholder="..." name="activity" [(ngModel)]="record.activity">
                            </div>
                            <div class="col-lg-1 text-lg-right"><label>Tipologia</label></div>
                            <div class="col-lg-2">
                                <select class="form-control form-control-sm" [(ngModel)]="record.id_type" name="id_type" #id_type="ngModel" required>
                                    <option *ngFor="let t of type_shops" [ngValue]="t.id">{{t.name}}</option>
                                </select>                                    
                            </div>
                            <div class="col-lg-1 text-lg-right"><label>Stato</label></div>
                            <div class="col-lg-2">
                                <select class="form-control form-control-sm" [(ngModel)]="record.status" name="status" #id_type="ngModel" required>
                                    <option value="1">Utilizzata</option>
                                    <option value="2">Non utilizzata</option>
                                </select>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-lg-2"><label>Anagrafica</label></div>
                            <div class="col-lg-10">
                                <div class="small-box bg-light mb-0">
                                    <app-selectfieldcustom (OnExplore)="selectAddress()" (OnRecordSelected)="OnSelectAddress($event)" (OnRecordView)="OnViewAddress($event)"
                                        [showViewButton]="true"
                                        [showSearchButton]="true"
                                        [recordSelected]="record.addressItem"
                                        [service]="addressesService"
                                        [fieldRender]="'name'"
                                        [class]="'form-control form-control-sm'"></app-selectfieldcustom>
                                    <div class="p-1" *ngIf="record.addressItem && record.addressItem.id>0">
                                        <small>
                                            <div class="row">
                                                <div class="col-lg-6">
                                                    <small>
                                                        <ng-container *ngIf="record.addressItem.address || record.addressItem.city">
                                                            <b>INDIRIZZO:</b><br>
                                                            {{record.addressItem.address}}<br>
                                                            {{record.addressItem.zip}} {{record.addressItem.city}} {{record.addressItem.country}}<br>
                                                        </ng-container>
                                                        <span *ngIf="record.addressItem.tax_code">COD. FISC.: {{record.addressItem.tax_code}}<br></span>
                                                        <span *ngIf="record.addressItem.vat_number">P.IVA: {{record.addressItem.vat_number}}</span>
                                                    </small>
                                                </div>
                                                <div class="col-lg-6 border-left" *ngIf="record.addressItem.contacts.length > 0">
                                                    <small>
                                                        <b>CONTATTI:</b><br>
                                                        <span *ngFor="let c of record.addressItem.contacts">{{c.value}}<br></span>
                                                    </small>
                                                </div>
                                            </div>
                                        </small>
                                        <div class="icon"><i class="fa fa-user"></i></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-lg-12">                               
                                <app-cities name="city" [enable_geocoding]="false" [latitude]="record.latitude" [longitude]="record.longitude" [address]="record.address" [country]="record.country" [city]="record.city"  [zip]="record.zip" (onEntrySelected)="record.address=$event.address;record.city=$event.city;record.country=$event.country;record.zip=$event.zip;"></app-cities>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-lg-2"><label>Latitudine<i class="fas fa-info-circle ml-2 text-gray help" 
                                title="Incolla le coordinate nel formato: LAT, LONG (@Google Map)"></i></label></div>
                            <div class="col-lg-2">                     
                                <input type="text" class="form-control form-control-sm" name="city" placeholder="..." [(ngModel)]="record.latitude" (ngModelChange)="verifyCoordinate()" >
                            </div>
                            <div class="col-lg-2 text-lg-right"><label>Longitudine</label></div>
                            <div class="col-lg-2">
                               <input type="text" class="form-control form-control-sm" name="zip" placeholder="..." [(ngModel)]="record.longitude" (ngModelChange)="verifyCoordinate()" >
                            </div>
                            <div class="col-lg-2"></div>
                            <div class="col-lg-2">
                                <button class="btn btn-sm btn-outline-secondary btn-block" (click)="openSearchGPS()" ><i class="fas fa-search-location mr-2"></i>Trova</button>                                    
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-lg-2"><label>Orari di apertura</label></div>
                            <div class="col-lg-10">
                                <app-timesheet *ngIf="record" [record]="record" [fieldname]="'opening_time'"></app-timesheet>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-lg-2"><label>Giorno di chiusura</label></div>
                            <div class="col-lg-4">
                                <select class="form-control form-control-sm" name="closed_day" [(ngModel)]="record.closed_day">
                                    <option >Lunedì</option>
                                    <option >Martedì</option>
                                    <option >Mercoledì</option>
                                    <option >Giovedì</option>
                                    <option >Venerdì</option>
                                    <option >Sabato</option>
                                    <option >Domenica</option>
                                </select>
                            </div>
                        </div>
                        
                    </div>

                    <!-- TAB: Informazioni -->
                    <div class="tab-pane" id="tabShop3" role="tabpanel" aria-labelledby="tab3-tab">
                        
                        
                        <div class="row mt-2">
                            <div class="col-12">
                                <app-customfields [origin]="record" [list]="record.customfields" [table]="'shops'" [id_table]="record.id" [condition_id]="record.id_type"></app-customfields>
                            </div>
                        </div>
                    </div>
                    <!-- TAB: Prodotti -->
                    <div class="tab-pane" id="tabShop4" role="tabpanel" aria-labelledby="tab4-tab">
                        <app-serialshop [id_shop]="id" [mode]="'embedded'" *ngIf="id != 0"></app-serialshop>
                    </div>
                    <!-- TAB: Contratti -->
                    <div class="tab-pane" id="tabShop5" role="tabpanel" aria-labelledby="tab5-tab">
                        <app-contracts [id_shop]="record.id" [mode]="'embedded'" *ngIf="id != 0"></app-contracts>
                    </div>
                    <!-- TAB: Scadenze -->
                    <div class="tab-pane" id="tabShop6" role="tabpanel" aria-labelledby="tab6-tab">
                        <app-deadlines [viewMode]="'table'" [filter_box]="false" [id_table]="record.id" [table]="'shops'" [mode]="'embedded'" *ngIf="id != 0"></app-deadlines>
                    </div>
                    <!-- TAB: Attività -->
                    <div class="tab-pane" id="tabShop7" role="tabpanel" aria-labelledby="tab7-tab">
                        <app-activities-list [id_table]="record.id" [table]="'shops'"  [id_address]="record.id_address" [reference]="record" [mode]="'embedded'" *ngIf="id != 0"></app-activities-list>
                    </div>
                    <!-- TAB: Avanzate -->
                    <div class="tab-pane" id="tabShop8" role="tabpanel" aria-labelledby="tab8-tab">                                    
                            <div class="form-group row">
                                <div class="col-3"><label>Magazzino di riferimento</label></div>
                                <div class="col-9">
                                    <select class="form-control form-control-sm" name="id_inventory" [(ngModel)]="record.id_inventory">
                                        <option value="0">Nessuno</option>
                                        <option value="{{i.id}}" *ngFor="let i of inventories">{{i.name}}</option>
                                        
                                    </select>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-3"><label>Parametri</label></div>
                                <div class="col-9">
                                    <textarea class="form-control form-control-sm" name="params" [(ngModel)]="record.params"></textarea>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
            <!-- Map, Address, Contacts -->
            <div class="col-12">
                <div class="m-4" *ngIf="id == 0">
                    <i class="fa-2x fas fa-info text-info mr-3"></i>
                    <i>Salva prima di procedere con l'immisione degli altri dati.</i>
                </div>
                <app-contact [list]="record.contacts" [table]="'shops'" *ngIf="id != 0"></app-contact>
                <app-notebox [list]="record.notes" [table]="'shops'" [id_table]="record.id" [id_address]="record.id" *ngIf="id != 0"></app-notebox>
            </div>
        </form> 
 

</app-window>
<app-toolbarmodel [model]="this"></app-toolbarmodel>