import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CalendarEvent, CalendarView } from 'angular-calendar';
import { Subject } from 'rxjs';
import { ActivitiesService } from '../../../services/activities.service';
import { UntypedFormBuilder } from '@angular/forms';
import { Helper } from 'projects/core/src/helper';
import { ActivitiesDetailsComponent } from '../activities-details/activities-details.component';
import { ModelList } from 'projects/core/src/include/modellist';
import { Activities } from '../../../db/activities';
import { Globals } from 'projects/core/src/globals';
import { ActivitiesListComponent } from '../list/list.component';

@Component({
  selector: 'app-activities-calendar',
  templateUrl: './calendar.component.html',
  styleUrl: './calendar.component.css'
})
export class ActivitiesCalendarComponent extends ModelList<Activities> implements OnInit {
  MODE_VIEW_MONTH=1
  MODE_VIEW_YEAR=2


  @Output()
  periodSelected:EventEmitter<any>=new EventEmitter<any>();

  @Output()
  dateSelected:EventEmitter<any>=new EventEmitter<any>();

  @Output()
  noEvent:EventEmitter<any>=new EventEmitter<any>();

  @Input()
  list=[];

  @Input()
  table="";

  @Input()
  where_join="";

  @Input()
  join="";


  modeView=this.MODE_VIEW_MONTH;

  locale: string = "it";
  today=new Date();
  viewDate: Date = new Date();
  
  date_selected=new Date();
  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  
  refresh: Subject<any> = new Subject();
  events: CalendarEvent[] = [];
  list_month=[];
  date_from;
  date_to;

  total=0;

  year=new Date().getFullYear();

  constructor(
    private activitiesService: ActivitiesService,
    private fb1: UntypedFormBuilder,

  ) { 
    super(activitiesService,fb1);
    this.detailViewModal=ActivitiesDetailsComponent;
  }

  ngOnInit(): void {
    this.handleEvent(this.today);

    
  }

  getItems(){
    this.activitiesService.getItemsGrouped(this.date_from,this.date_to,"day",this.table,this.join,this.where_join).subscribe((items)=>{
      this.events=[];
      for(let i of items){
        let c:CalendarEvent={} as CalendarEvent;
        c.start=new Date(i.date);
        c.title=i.type;    
        
        c.meta=i;
        c.meta.count=i.count;
        c.meta.total=i.total;
        this.events.push(c);
      }

      
      
      
    });
  }

  getItemsByMonth(){
    this.activitiesService.getItemsGrouped(this.year+"-01-01",this.year+"-12-31","month",this.table,this.join,this.where_join).subscribe((items)=>{
      this.list_month=[];
      this.total=0;
      
      this.list_month.push({"year":0,"month":1,"label":"Gennaio","deadlines":[],"total":0,"count":0});
      this.list_month.push({"year":0,"month":2,"label":"Febbraio","deadlines":[],"total":0,"count":0});
      this.list_month.push({"year":0,"month":3,"label":"Marzo","deadlines":[],"total":0,"count":0});
      this.list_month.push({"year":0,"month":4,"label":"Aprile","deadlines":[],"total":0,"count":0});
      this.list_month.push({"year":0,"month":5,"label":"Maggio","deadlines":[],"total":0,"count":0});
      this.list_month.push({"year":0,"month":6,"label":"Giugno","deadlines":[],"total":0,"count":0});
      this.list_month.push({"year":0,"month":7,"label":"Luglio","deadlines":[],"total":0,"count":0});
      this.list_month.push({"year":0,"month":8,"label":"Agosto","deadlines":[],"total":0,"count":0});
      this.list_month.push({"year":0,"month":9,"label":"Settembre","deadlines":[],"total":0,"count":0});
      this.list_month.push({"year":0,"month":10,"label":"Ottobre","deadlines":[],"total":0,"count":0});
      this.list_month.push({"year":0,"month":11,"label":"Novembre","deadlines":[],"total":0,"count":0});
      this.list_month.push({"year":0,"month":12,"label":"Dicembre","deadlines":[],"total":0,"count":0});

      for(let i of items){
        this.list_month[Helper.convertString2Date(i.date).getMonth()]['deadlines'].push(i);
        this.list_month[Helper.convertString2Date(i.date).getMonth()]['year']=Helper.convertString2Date(i.date).getFullYear();
      }

      for(let m of this.list_month){
        m.total=0;
        m.count=0;
        for(let d of m.deadlines){
          m.total=m.total+parseFloat(d.total);
          m.count=m.count+parseInt(d.count);
        }
        this.total=this.total+m.total;
        
      }



     





    });
  }

  

  monthClicked(m:any):void{

    //ricava il periodo temporale
    let date=Helper.convertString2Date(m.date);

    let date_from=Helper.convertString2Date(date.getFullYear()+"-"+ (date.getMonth()+1)+"-01");

    var date_to = new Date(date.getFullYear(),date.getMonth() + 1, 0);

    /*
    Globals.modal.showModal(DeadlinesComponent,[
      {"name":"mode","value":"modal"},
      {"name":"filter_box","value":true},
      {"name":"viewMode","value":"table"},
      
      {"name":"date_from","value":date_from},
      {"name":"date_to","value":date_to},
      {"name":"id_type","value":m.id_type.toString()},
      {"name":"default_filterdate","value":99},
      {"name":"table","value":this.table}
      
    ],()=>{

    });
    */
  }

  dayClicked(d:any): void {
    this.date_selected=d.date;

    
   
    Globals.modal.showModal(ActivitiesListComponent,[
      {"name":"mode","value":"modal"},
      {"name":"filter_box","value":false},
      {"name":"isSelectable","value":false},
      {"name":"modeView","value":3},
      {"name":"date_from","value":Helper.convertDateControl(d.date)},
      {"name":"date_to","value":Helper.convertDateControl(d.date)},
      
    ],()=>{

    });

    
  }


  handleEvent(d:any) {

    
  
    this.date_from=Helper.convertDateControl(new Date(d.getFullYear(), d.getMonth(), 1)); 
    this.date_to=Helper.convertDateControl(new Date(d.getFullYear(), d.getMonth()+1, 0)); 

    


   // this.periodSelected.emit({"date_from":Helper.convertDateControl(this.date_from),"date_to":Helper.convertDateControl(this.date_to)});
    this.getItems();
     
   }
}
