import { Component, OnInit, Input, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-filtercontainer',
  templateUrl: './filtercontainer.component.html',
  styleUrls: ['./filtercontainer.component.css']
})

export class FiltercontainerComponent implements OnInit {

  @ViewChild("list")
  listFilter:TemplateRef<any>;
  listFilterRef;

  @Input()
  title:string="Filtri...";
  
  
  constructor(
    private modalService:NgbModal
  ) { }

  ngOnInit(): void {
  }

  openFilter(){
    this.listFilterRef=this.modalService.open(this.listFilter);
  }

  closeFilter(){
    if(this.listFilterRef){
      this.listFilterRef.close("success");
    }
  }
}
