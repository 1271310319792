import { Component, OnInit,Input, ViewChild, ElementRef, AfterViewInit, HostListener } from '@angular/core';
import { QrcodereaderComponent } from 'projects/core/src/common/qrcodereader/qrcodereader.component';
import { Globals } from 'projects/core/src/globals';
import { InventoryMode, SNAvaible } from '../../../db/inventories';
import {InventoriesDetailsService} from '../../../services/inventoriesdetails.service';
import { InstallationsService } from '../../../services/installations.service';
@Component({
  selector: 'app-loadserials',
  templateUrl: './loadserials.component.html',
  styleUrls: ['./loadserials.component.sass']
})
export class LoadserialsComponent implements OnInit,AfterViewInit {
  
  @HostListener('window:keydown', ['$event'])
  onKeyDown(event) {

    if(event.keyCode==119){ //F8
      this.confirm();
    }


    
  }

  
  @ViewChild("serialsToLoadBox")
  serialsToLoadBox:ElementRef;

  serialsToLoad:string;

  loading=false;
  
  id_inventory=null; //se definito visualizza i seriali solo di questo inventario
  
  eliminateDuplicates(arr) {
    var i,
        len = arr.length,
        out = [],
        obj = {};
  
    for (i = 0; i < len; i++) {
      obj[arr[i]] = 0;
    }
    for (i in obj) {
      out.push(i);
    }
    return out;
  }

  serials:SNAvaible[]=[];

  serialsList:SNAvaible[]=[];


  @Input()
  quantity:number;

  @Input()
  type:number;

  @Input()
  snpattern:string;

  @Input()
  id_product:number;

  showAll=false;

  get countLoaded(){
    try{
      let serialsarray=this.serialsToLoad.split(/\n|;/);
      //serialsarray=this.serialsToLoad.split("\n");
      //elimina seriali vuoti
      serialsarray=serialsarray.filter(x=>x!=null && x!="");
      return serialsarray.length;
    }catch{
      return 0;
    }
  }

  get countSelected(){
    try{
      let c=0;
      for(let s of this.serialsList)
        if(s.selected)
          c=c+1;

      return c;
    }catch{
      return 0;
    }
  }

  constructor(
    private inventoriesdetailService:InventoriesDetailsService,
    private installationService:InstallationsService
  ) { }

  ngOnInit(): void {

    this.filterSerials();
    
    
  }

  filterSerials(switchShowAll=false){
    if(switchShowAll)
      this.showAll=!this.showAll;

    if(this.showAll){
      this.loading=true;
      this.installationService.getInstallationByIdProduct(this.id_product).subscribe((items)=>{
        
        this.serialsList=[];
        for(let i of items){
          let s:SNAvaible=new SNAvaible();
          s.sn=i.sn;
          s.id_inventory=0;
          s.selected=false;
          s.avaible=1;
          
          s['inventoryname']=i.shop.name;
          this.serialsList.push(s);

        }
        this.loading=false;
      });
    }else{
      this.serialsList=[];
      for(let s of this.serials){
        this.serialsList.push(s);
      }
      
      if(this.id_inventory){
        this.serialsList=this.serialsList.filter(x=>x.id_inventory==this.id_inventory);
      }
    }
  }
  


  ngAfterViewInit():void{
    if(this.type==InventoryMode.load)
      this.serialsToLoadBox.nativeElement.focus();
  }

  confirm(force=false){
    this.serials=this.serialsList;
    if(this.type==InventoryMode.load){
      let serialsarray=this.serialsToLoad.split(/\n|;/);
      
      serialsarray=this.eliminateDuplicates(serialsarray);
      this.serialsToLoad=serialsarray.join("\n");

      //elimina seriali vuoti
      serialsarray=serialsarray.filter(x=>x!=null && x!="");
      
      //verifica se i seriali inseriti corrispondono alle quantità di caricare
      if(serialsarray.length<this.quantity){
        alert("I seriali inseriti non corrispondono alle quantità da caricare");
        this.serialsToLoadBox.nativeElement.focus();
        return;
      }

      this.serialsList=[];
      for(let s of serialsarray){
        let s1:SNAvaible={} as SNAvaible;
        s1.sn=s;
        s1.selected=true;
        this.serialsList.push(s1);
      }



    }else{
      let c:number=0;
      for(let s of this.serialsList){
        if(s.selected)
            c=c+1;
      }

      if(c!=this.quantity && !force){
        alert("I seriali selezionati non corrispondono alle quantità da scaricare");
        this.serialsToLoadBox.nativeElement.focus();
        return;
      }


    }
    this['modalWindow'].close("success");
  }

  close(){
    this['modalWindow'].close();
  }


  scan(){
   
      Globals.modal.showModal(QrcodereaderComponent,[],(instance)=>{
        if(instance!=null){
          if(this.serialsToLoad==undefined)
            this.serialsToLoad= instance['code'];
          else
            this.serialsToLoad= this.serialsToLoad+";"+instance['code'];
        }
     });
   
  }

  generate(){
    this.inventoriesdetailService.generateSerials(this.id_product,this.snpattern,this.quantity).subscribe((items)=>{
      this.serialsToLoad=items.join("\n");
    })
  }
}
