<table class="table table-sm w-100">
    <thead>
        <th>Giorno</th>
        <th>Dalle</th>
        <th>Alle</th>
        <th>Dalle</th>
        <th>Alle</th>
        <th></th>    
    </thead>
    <tr *ngFor="let t of record[fieldname]" [class.bg-success]="t.day==daynumber">
        <td>{{getDate(t.day)}}{{t.day}}{{daynumber}}</td>
        <ng-container *ngIf="edit">
            <td><input type="time" name="{{t.day}}_from1" [(ngModel)]="t.from1"></td>
            <td><input type="time" name="{{t.day}}_to1" [(ngModel)]="t.to1"></td>
            <td><input type="time" name="{{t.day}}_from2" [(ngModel)]="t.from2"></td>
            <td><input type="time" name="{{t.day}}_to2" [(ngModel)]="t.to2"></td>
            <td>
                <button class="btm btn-sm btn-info float-right ml-1" (click)="fill(t)"><i class="fa fa-clone"></i></button>
                <button class="btm btn-sm btn-danger float-right" (click)="erase(t)"><i class="fa fa-trash-alt"></i></button>        
            </td>
        </ng-container>
        <ng-container *ngIf="!edit">
            <td>{{t.from1}}</td>
            <td>{{t.to1}}</td>
            <td>{{t.from2}}</td>
            <td>{{t.to2}}</td>
            <th></th>
        </ng-container>
    </tr>
</table>
<ng-container *ngIf="edit">
    <button class="btn btn-sm btn-outline-secondary" (click)="newTimesheet()">Azzera</button>
</ng-container>                           