<div role="document ">
    <div class="modal-content {{class}}">
      <div class="modal-header" *ngIf="showHeader">
        <b>{{title}}</b>
        <div class="card-tools">
          <button type="button" class="btn btn-tool" (click)="close()" >
            <i class="fas fa-times"></i>
          </button>
        </div>
      </div>
      <div class="modal-body">
        <ng-content #content></ng-content>
      </div>
      <div class="modal-footer" *ngIf="showFooter">
        <button type="button" class="btn btn-outline-secondary" (click)="close('cancel')">{{lblCancelButton}}</button>
        <button *ngIf="showConfirmButton" type="button" class="btn btn-outline-success" (click)="close('success')">{{lblConfirmButton}}</button>
      </div>
    </div>
  </div>