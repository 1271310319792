<section class="content">
    <div class="">
        <ul class="nav nav-tabs b-1" id="myTab" role="tablist" *ngIf="header">
            <li class="nav-item">
                <a class="nav-link" [class.active]="tab==1" (click)="tab=1">
                    <i class="fas fa-users"></i><span class="d-none d-sm-inline-block ml-2">Utenti</span></a>
            </li>
            <li class="nav-item">
                <a class="nav-link" [class.active]="tab==2" (click)="tab=2">
                    <i class="fas fa-door-open"></i><span class="d-none d-sm-inline-block ml-2">Ruoli</span></a>
            </li>
        </ul>
        <div class="tab-content p-1" id="myTabContent" >
            <div class="tab-pane fade show" [class.active]="tab==1" *ngIf="tab==1">
                <app-listusers></app-listusers>
            </div>
            <div class="tab-pane fade show" [class.active]="tab==2" *ngIf="tab==2">
                <app-listusergroups></app-listusergroups>
            </div>
            
        </div>
    </div>
</section>