<app-windowlist [model]="this">
    <div class="row my-1">
        <div class="col-lg-2 pr-md-0">
            <app-filtercontainer [title]="'Filtra per attività, date, marche'">
                <ul class="card small-box list-group p-1 mb-2" *ngIf="type_activities.length>0">
                    <li class="list-group-item" [class.active]="filter_id_type.value==''" (click)="filter_id_type.value='';updateList();">Tutte le attività</li>
                    <li class="list-group-item" *ngFor="let a of type_activities" [class.active]="filter_id_type.value==a.id" (click)="filter_id_type.value=a.id;updateList();">{{a.name}}</li>
                    <div  *ngIf="type_activities.length>2" class="icon"><i class="fas fa-tasks"></i></div>
                </ul>
                <app-filterdate [date_type]="98" [class]="'card p-1 mb-2'" [filter_date]="filter_date" (onSelect)="updateList()"></app-filterdate>
                <ul class="card small-box list-group p-1 mb-2" *ngIf="brands.length>0">
                    <li class="list-group-item" [class.active]="filter_id_brand.value==''" (click)="filter_id_brand.value='';updateList();">Tutte le marche</li>
                    <li class="list-group-item" *ngFor="let a of brands" [class.active]="filter_id_brand.value==a.id" (click)="filter_id_brand.value=a.id;updateList();">{{a.name}}</li>
                    <div  *ngIf="brands.length>2" class="icon"><i class="fas fa-tasks"></i></div>
                </ul>
            </app-filtercontainer>
        </div>
        <div class="col-lg-10">
            <div class="card card-outline">
                <div class="p-0">
                    <div class="row">
                        <div class="col-6 col-lg-5 pr-lg-0">
                            <div class="input-group input-group-sm p-1" >
                                <input type="text" name="table_search" class="form-control float-right" placeholder="cerca..." [(ngModel)]="filter_search.value" (keyup.enter)="updateList()">
                                <div class="input-group-append ">
                                    <button type="submit" class="btn btn-default text-primary" title="Avvia ricerca" (click)="updateList();"><i class="fas fa-search"></i></button>
                                    <button class="btn btn-default text-danger" *ngIf="filter_search.value!=''"  title="Annulla ricerca" (click)="filter_search.value='';updateList();"><span id="searchclear" class="fas fa-times"></span></button>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 col-lg-2 pr-lg-0">
                            <div class="input-group input-group-sm p-1">
                                <select class="custom-select" [(ngModel)]="filter_id_type_installation.value" (ngModelChange)="updateList()" [class.alert-info]="filter_id_type_installation.value">
                                    <option value="">Tutte le tipologie</option>
                                    <option *ngFor="let t of type_product" [ngValue]="t.id">{{t.name}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-6 col-lg-2 pr-lg-0">
                            <div class="input-group input-group-sm p-1">
                                <select class="custom-select" [(ngModel)]="filter_id_user.value" (ngModelChange)="updateList()" [class.alert-info]="filter_id_user.value">
                                    <option value="">Tutti gli utenti</option>
                                    <option *ngFor="let u of users" [ngValue]="u.id">{{u.username}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-6 col-lg-3" *ngIf="mode!='modal'">
                            <app-windowlistbuttons 
                            [model]="this" 
                            [table]="'installations'" 
                            [openDetailExtra]="{'name':'id_shop','value':id_shop}" 
                            [funs]="[]"
                            ></app-windowlistbuttons>
                            <!-- 
                            <button  title="Archivia"  (click)="archive()" [disabled]="form.value.id.length == 0" class="btn btn-primary btn-xs float-right mx-1 mt-1"><i class="fas fa-archive"></i></button>
                            <button  title="Cestina" *ngIf="filter_status.value == '1'" (click)="trash()" [disabled]="form.value.id.length == 0" class="btn btn-danger btn-xs float-right ml-1 mt-1"><i class="far fa-trash-alt"></i></button>
                            <button  title="Elimina" *ngIf="filter_status.value == '2'" (click)="delete()" [disabled]="form.value.id.length == 0" class="btn btn-danger btn-xs float-right ml-1 mt-1"><i class="fas fa-trash"></i></button>
                            <button  title="Ripristina" *ngIf="filter_status.value == '2'" (click)="undo()" [disabled]="form.value.id.length == 0" class="btn btn-secondary btn-xs float-right ml-1 mt-1"><i class="fas fa-undo"></i></button>
                            <button  title="Esporta" (click)="export()" class="btn btn-secondary btn-xs float-right ml-1 mt-1"><i class="fas fa-file-export"></i></button>
                            <button  title="Aggiungi" (click)="openDetail(null,{'name':'id_shop','value':id_shop})" class="btn btn-success btn-xs float-right ml-1 mt-1"><i class="fas fa-plus"></i></button>  -->
                        </div>
                    </div>

                    <div *ngIf="!list || list.length==0" class="m-5 text-center text-secondary">
                        <i class="icon fas fa-info-circle mr-2"></i><i>{{messageStatus}}</i>
    
                    </div>
                    <form *ngIf="list && list.length>0" [formGroup]="form">
                        <table class="table table-nowrap table-striped table-sm table-head-fixed table-hover m-0 text-sm">
                            <thead class="bg-light">
                                <th>
                                    <div #checkboxselectall class="icheck-primary d-inline mr-3">
                                        <input type="checkbox" title="Seleziona tutti"
                                        (change)="onChangeAll($event.target.checked)" />
                                    </div>
                                </th>
                                <th>Modello / Numero Seriale</th>
                                <th class="d-none d-lg-table-cell">Ubicazione attuale</th>
                                <th>Ultima Attività</th>
                                <th class="d-none d-lg-table-cell">Prossima scadenza</th>
                                <th></th>
                            </thead>
                            <tbody>
                                <!-- elemento ripetuto -->
                                <tr *ngFor="let record of list">
                                    <td>
                                        <div class="icheck-primary d-inline mr-3">
                                            <input type="checkbox" #checkrecord id_record="{{record.id}}"
                                            (change)="onChange(record.id, $event.target.checked)" [checked]="checkedAll"
                                            />
                                        </div>
                                    </td>
                                    <td>
                                        <span [class.trashed]="record.status == 2" [class.disabled]="mode=='modal'" (click)="openDetail(record)" class="pointer">
                                            <span *ngIf="record.reference && record.reference.product"><b>{{record.reference.product.brand}}</b> {{record.reference.product.name}}</span>
                                        </span>
                                        <span class="badge badge-warning" *ngIf="record.reference && record.reference.product && record.reference.product.id_category==id_category_log && !record.last_log.id" title="Nessun monitoraggio chilometri rilevato!"><i class="fa fa-exclamation-triangle"></i></span>
                                        <br/>
                                        <small>
                                            <i>
                                                <b>{{ record.reference.sn }}</b>
                                                <br/>{{ record.reference.matricola }}
                                            </i>
                                        </small>
                                        &nbsp;<i *ngIf="record.loguseralert>0"  title="Ci sono alcune avvertenze da visionare" class="text-warning text-warning fas fa-exclamation-triangle"><sup><span class="badge badge-danger">{{record.reference.loguseralert}}</span></sup></i>
                                    </td>
                                    <td class="d-none d-lg-table-cell">
                                        <ng-container *ngIf="record.reference.shop">
                                            <i class="fas fa-store-alt pointer" (dblclick)="moveInstallation(record.reference)" title="Doppio Click per spostamento rapido"></i>&nbsp;<span [class.trashed]="record.reference.shop.status==2">{{record.reference.shop.name}}
                                                <div *ngIf="record.reference.shop.status==2" class="badge badge-warning"><i class="fas fa-exclamation-triangle"></i>&nbsp;cestinato</div>
                                            </span>
                                            <span class="badge badge-danger" *ngIf="record.reference.shop.type_params!='default'">{{record.reference.shop.type}}</span>
                                            <br/>
                                            <ng-container *ngIf="record.reference.shop.addressItem">
                                                <small [class.trashed]="record.reference.shop.addressItem.status==2">
                                                    <i class="fa fa-user"></i>&nbsp;{{record.reference.shop.addressItem.name}}
                                                    <div *ngIf="record.reference.shop.addressItem.status==2" class="badge badge-warning"><i class="fas fa-exclamation-triangle"></i>&nbsp;cestinato</div>
                                                </small>
                                            </ng-container>
                                        </ng-container>
                                    </td>
                                   <td>
                                        <b>{{record.date | date:"dd/MM/Y"}}</b><br/>
                                            {{record.type}}<br/>
                                            <small>{{record.username}}</small>
                                        <small *ngIf="record.note">
                                            <div class="alert alert-info p-1">
                                                <i class="fas fa-thumbtack mr-2"></i>{{record.note}}
                                            </div>
                                        </small>
                                   </td>
                                   <td class="d-none d-lg-table-cell">
                                       <ng-container *ngIf="record.reference && record.reference.nextDeadline">
                                            <strong>{{record.reference.nextDeadline.date | date:"dd/MM/Y"}}</strong><br/>
                                        {{record.reference.nextDeadline.type}}
                                        </ng-container>
                                   </td>
                                    <td>
                                        <button type="button" *ngIf="mode!='modal'" (click)="openDetail(record.reference)"  title="Modifica / Vedi" class="btn btn-secondary btn-xs float-right m-1">
                                            <i class="fas fa-pencil-alt"></i></button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </form>
                </div>
                <div class="card-footer clearfix py-1">
                    <app-pagination [model]="this" ></app-pagination>
                </div>
            </div>
        </div>
    </div>
</app-windowlist>