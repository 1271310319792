<app-windowlist [model]="this">
    <div class="row">
        <div class="col-12">
            <div class="card px-1">               
                    <div class="row">
                        <div class="col-md-6">
                            <div class="input-group input-group-sm p-1">
                                <input type="text" #table_search name="table_search" class="form-control form-control-sm" placeholder="cerca per ragione sociale, indirizzo, comune, P.IVA, codice fiscale, SdI, PEC ..." [(ngModel)]="filter_search.value" (keyup.enter)="getItems()">
                                <div class="input-group-append">
                                    <button type="submit" class="btn btn-default text-primary"  title="Avvia ricerca" (click)="getItems()"><i class="fas fa-search"></i></button>
                                    <button class="btn btn-default text-danger"  title="Annulla ricerca" *ngIf="filter_search.value!=''" (click)="filter_search.value='';getItems();"><span id="searchclear" class="fas fa-times"></span></button>
                                    <button class="btn btn-default"   title="Regole" data-toggle="collapse" data-target="#advancedSearchPanel"  aria-expanded="false" aria-controls="advancedSearchPanel" ><span id="searchclear" class="fas fa-sliders-h"></span></button>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <select class="form-control form-control-sm m-1" [(ngModel)]="filter_id_type.value" (ngModelChange)="getItems()" [class.alert-info]="filter_id_type.value">
                                <option value="0">Tutte le tipologie</option>
                                <option *ngFor="let t of type_address" [ngValue]="t.id">
                                    {{t.name}}</option>
                            </select>
                        </div>
                        <div class="col-md-2">
                            <div class="btn-group w-100 p-1">
                                <button [class.bg-primary]="!listmode" type="button" class="btn btn-sm btn-default text-xs border" (click)="listmode=false;ordering.field='a.created';ordering.mode='DESC';getItems()">Card</button>
                                <button [class.bg-primary]="listmode" type="button" class="btn btn-sm btn-default text-xs border" (click)="listmode=true;ordering.field='a.name';ordering.mode='ASC';getItems()">Lista</button>   
                            </div>
                        </div>
                        <div class="col-md-2">
                            <app-windowlistbuttons 
                            [model]="this" 
                            [table]="'addresses'" 
                            [funs]="[
                                {'name':'Altri comandi','value':''},
                                {'name':'Unisci anagrafiche','value':'joinAddresses'}
                            ]"
                            ></app-windowlistbuttons>
                        </div>
                        <div class="col-12">
                            <div  class="small-box bg-light m-1 p-1 collapse" id="advancedSearchPanel">
                                <div class="row">
                                    <div class="col-xs-12 col-lg-11">
                                        <app-advancedsearch [id_field]="'a.id'" [table]="'addresses'" [filters]="advancedFilter"></app-advancedsearch>
                                    </div>
                                    <div class="col-xs-12 col-lg-1 pl-1">
                                        <button title="Filtra" class="btn btn-secondary btn-block btn-sm" (click)="getItems()"><i class="fa fa-filter"></i><small class="ml-1 d-none d-lg-inline">Filtra</small></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 mb-1">
                            <small class="ml-1">
                                <input type="checkbox" class="mr-1" name="filter_search_contact" [(ngModel)]="filter_search_contact.enabled">
                                <i>Cerca anche nei contatti</i>
                            </small>
                            <small *ngIf="listmode" class="ml-3"><i class="text-info fas fa-sort-alpha-down mr-1"></i>Ordinamento alfabetico</small>
                            <small *ngIf="!listmode" class="ml-3"><i class="text-info fas fa-sort-amount-down mr-1"></i>Ordinamento dal più recente</small>
                            <small *ngIf="mode!='modal'" #checkboxselectall class="float-right ml-3 mr-2">
                                <i>Seleziona tutti</i>
                                <input type="checkbox" class="ml-2" (change)="onChangeAll($event.target.checked)" />                                
                            </small>                           
                        </div>                       
                    </div>
                    <div *ngIf="!list || list.length==0" class="m-5 text-center text-secondary">
                        <i class="icon fas fa-info-circle mr-2"></i><i>{{messageStatus}}</i>
                    </div>
                    <form *ngIf="list && list.length>0" [formGroup]="form">

                        <!-- List viewmode -->
                        <ul class="list-group border-top" *ngIf="listmode">
                            <li class="list-group-item list-group-item-action border-bottom pl-0" *ngFor="let record of list">
                                <div class="row">
                                    <div class="col-1 p-1 d-lg-block d-none text-center">
                                        <img *ngIf="record.logo" class="w-75" src="{{record.logo}}">
                                        <i *ngIf="!record.logo" class="fa fa-user-circle fa-3x text-light"></i>
                                    </div>
                                    <div class="col-6 pl-lg-0 d-flex flex-column justify-content-center">
                                        <h6 class="mb-0 text-capitalize text-primary pointer" title="Vedi/Modifica" (click)="openDetail(record)">
                                            <strong>{{record.name}}</strong>
                                        </h6>
                                        <small>
                                            <span class="text-capitalize">{{record.address}}</span>
                                            <span *ngIf="record.address">, </span>
                                            <b class="text-capitalize">{{record.city}}</b><span class="text-uppercase" *ngIf="record.city && record.country && record.country!='EE'"> {{record.country}}</span><br/>
                                            <span *ngIf="record.vat_number && record.vat_number!='XXXXXXXXXXX'">P.IVA: <span class="text-uppercase">{{record.vat_number}}</span>
                                                <span *ngIf="record.tax_code && record.tax_code!='XXXXXXXXXXX'">  /  </span>
                                            </span>
                                            <span *ngIf="record.tax_code && record.tax_code!='XXXXXXXXXXX'">Cod. Fisc.: <span class="text-uppercase">{{record.tax_code}}</span></span>
                                        </small>
                                    </div>
                                    <div class="col-3 d-flex flex-column justify-content-center">
                                        <div *ngIf="checkPermissions('showBalance')">                                            
                                            <small *ngIf="record.balance" title="{{record.balance}}">
                                                <!-- <i class="fas fa-euro-sign mr-2"></i> -->
                                                (SALDO:&ensp;<span [class.text-red]="record.balance < -0.01" [class.text-success]="record.balance > 0.01">{{record.balance | currency: " Euro"}}</span>)
                                            </small>
                                        </div>
                                        <div *ngIf="record.notes.length>0">{{record.notes.length}}
                                            <small *ngIf="record.notes.length>1">note presenti</small>
                                            <small *ngIf="record.notes.length==1">nota presente</small>
                                        </div>
                                    </div>
                                    <div class="col-3 col-lg-2 px-0 text-right">
                                        <input type="checkbox" #checkrecord id_record="{{record.id}}" title="{{record.id}}"
                                        (change)="onChange(record.id, $event.target.checked)" class="m-1 float-right" [checked]="checkedAll"/>                                        
                                        <button type="button" *ngIf="mode=='modal'" class="btn btn-secondary btn-xs float-right" (click)="selectRecord(record);">
                                            <span class="d-none d-sm-inline-block m-1">Seleziona</span><i class="fas fa-chevron-right"></i></button>
                                        <button type="button" *ngIf="mode!='modal'" (click)="openDetail(record)"  title="Modifica / Vedi" class="btn btn-secondary btn-xs float-right mr-1">
                                            <i class="fas fa-pencil-alt"></i></button>
                                        <button type="button" *ngIf="mode!='modal'"  (click)="notify(record);" title="Notifica" class="btn btn-primary btn-xs float-right mr-1">
                                            <i class="far fa-paper-plane"></i></button>
                                    </div>
                                </div>
                            </li>
                        </ul>

                        <!-- Card viewmode -->
                        <div class="row p-2" *ngIf="!listmode">
                            <div class="col-12 col-lg-6 col-xl-3" *ngFor="let record of list">
                                <div class="card mb-lg-4">                                  
                                    <ng-container *ngIf="checkPermissions('showBalance')">
                                        <div *ngIf="record.balance" title="Saldo di {{record.balance}} €" class="ribbon-wrapper help">
                                            <div class="ribbon bg-gray" [class.bg-danger]="record.balance < -0.01" [class.bg-success]="record.balance > 0.01">
                                                <small><i class="fas fa-euro-sign"></i>&ensp;{{record.balance | currency: " "}}</small>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <div class="card-body py-0">
                                        <div class="row pt-1">
                                            <input type="checkbox" #checkrecord id_record="{{record.id}}" title="{{record.id}}"
                                                (change)="onChange(record.id, $event.target.checked)" class="float-right" [checked]="checkedAll"/>
                                            <!-- <small class="float-right text-xs text-gray mx-3">{{record.id}}</small> -->
                                            <ng-container *ngIf="record.notes.length>0">
                                                <i *ngIf="record.notes.length==1" class="far fa-sticky-note text-primary ml-3 help" title="C'è un'annotazione"></i>
                                                <i *ngIf="record.notes.length>1" class="far fa-sticky-note text-primary ml-3 help" title="Ci sono {{record.notes.length}} annotazioni"></i>
                                                <div class="text-primary" style="position: relative;left: -0.75rem;font-size: 0.5rem;top: 0.15rem;">{{record.notes.length}}</div>
                                            </ng-container>
                                            <!-- <ng-container *ngIf="record.files.length>0">
                                                <i *ngIf="record.files.length==1" class="far fa-sticky-note text-primary ml-3 help" title="C'è un file"></i>
                                                <i *ngIf="record.files.length>1" class="far fa-sticky-note text-primary ml-3 help" title="Ci sono {{record.files.length}} file"></i>
                                                <div class="text-primary" style="position: relative;left: -0.75rem;font-size: 0.5rem;top: 0.15rem;">{{record.files.length}}</div>
                                            </ng-container>   -->
                                        </div>
                                        <div class="row">
                                            <div class="col-3 pr-0 d-flex align-items-center justify-content-center">
                                                <img *ngIf="record.logo" class="w-100" src="{{record.logo}}">
                                                <i *ngIf="!record.logo" class="fa fa-user-circle fa-3x text-light"></i>
                                            </div>                                                                                
                                            <div class="col-9 pr-2 d-flex flex-column justify-content-center" style="min-height:7rem">
                                                <h6 class="mb-0 text-capitalize pointer" (click)="openDetail(record)" title="Vedi/Modifica">
                                                    <strong>{{record.name}}</strong>
                                                </h6>
                                                <small>
                                                    <span class="text-capitalize">{{record.address}}</span>
                                                    <span *ngIf="record.address">, </span>
                                                    <b class="text-capitalize">{{record.city}}</b><span class="text-uppercase" *ngIf="record.city && record.country && record.country!='EE'"> {{record.country}}</span><br/>
                                                    <span *ngIf="record.vat_number && record.vat_number!='XXXXXXXXXXX'">P.IVA: <span class="text-uppercase">{{record.vat_number}}</span>
                                                        <span *ngIf="record.tax_code && record.tax_code!='XXXXXXXXXXX'">  /  </span>
                                                    </span>
                                                    <span *ngIf="record.tax_code && record.tax_code!='XXXXXXXXXXX'">Cod. Fisc.: <span class="text-uppercase">{{record.tax_code}}</span></span>
                                                </small>
                                            </div>                                             
                                        </div>
                                    </div>
                                    <div class="card-footer p-1 text-right">                                        
                                        <div class="float-left mt-1">
                                            <small class="d-none d-lg-inline text-xs text-gray help" title="Data di creazione">
                                                <i class="far fa-calendar-plus mr-1"></i>{{record.created | date:"dd MMMM yyyy"}}
                                            </small>
                                                                                     
                                        </div>                                        
                                        <a type="button"  *ngIf="mode=='modal'" class="btn btn-secondary btn-xs" (click)="selectRecord(record);">
                                            <span class="d-none d-sm-inline-block m-1">Seleziona</span><i class="fas fa-chevron-right"></i></a>
                                        <a type="button" *ngIf="mode!='modal'" (click)="notify(record);" title="Notifica" class="btn btn-primary btn-xs mr-1">
                                            <i class="far fa-paper-plane"></i></a>
                                        <a type="button" *ngIf="mode!='modal'" (click)="openDetail(record)"  title="Modifica / Vedi" class="btn btn-secondary btn-xs mr-1">
                                            <i class="fas fa-pencil-alt"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </form>
                <div class="card-footer clearfix py-1">
                    <app-pagination [model]="this" ></app-pagination>
                </div>
            </div>
        </div>
    </div>
</app-windowlist>
