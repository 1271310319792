import { Component, OnInit, Input,Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-cash-flow-container',
  templateUrl: './cash-flow.component.html',
  styleUrls: ['./cash-flow.component.sass'],
})

export class CashFlowContainerComponent  implements OnInit {
  
  @Input()
  section="";
  
  @Input()
  header:boolean=true;

  @Input()
  filter_box:boolean=true;
  
  tab=1;

  ngOnInit(){

    if(this.section){
      switch(this.section){
        case "list":
          this.tab=1;
          break;
        case "overview":
          this.tab=2;
          break;
        case "cashregister":
          this.tab=3;
          break;
        case "analytics":
          this.tab=4;
          break;          
      }
    }
  }

}
