<app-window [title]="title" >
    <form role="form" id="detailForm">
        
        <!-- TAB GENERALI-->
        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item ">
                <a class="nav-link" [class.active]="tab==1" (click)="tab=1">
                    <i class="fas fa-address-card"></i><span class="d-none d-sm-inline-block ml-2">Indirizzo e Fatturazione</span></a>
            </li>
            <li class="nav-item">
                <a class="nav-link" [class.active]="tab==2" (click)="tab=2">
                    <i class="fas fa-info"></i><span class="d-none d-sm-inline-block ml-2">Altri dati</span></a>
            </li>
            <!-- <li class="nav-item">
                <a class="nav-link" id="profile-tab" data-toggle="tab" href="#tab4" role="tab" aria-controls="profile" aria-selected="false">
                    <i class="fas fa-mail-bulk"></i><span class="d-none d-sm-inline-block ml-2">Contatti<span *ngIf="record.contacts && record.contacts.length>0" class="badge badge-warning ml-1">{{record.contacts.length}}</span></span></a>
            </li> -->
            <ng-container>
                <ng-container *ngIf="record.id">
                    <li class="nav-item" *ngIf="checkPermissions('showNotes')" >
                        <a class="nav-link" [class.active]="tab==3" (click)="tab=3">
                            <i class="far fa-sticky-note"></i><span class="d-none d-sm-inline-block ml-2">Note<span *ngIf="record.notes && record.notes.length>0" class="badge badge-warning ml-1">{{record.notes.length}}</span></span></a>
                    </li>
                    <li class="nav-item" *ngIf="checkPermissions('showFiles')">
                        <a class="nav-link" [class.active]="tab==4" (click)="tab=4">
                            <i class="fas fa-hdd"></i><span class="d-none d-sm-inline-block ml-2">Files<span class="badge badge-info ml-1" *ngIf="record.files && record.files.length>0">{{record.files.length}}</span></span></a>
                    </li>
                </ng-container>
            </ng-container>
        </ul>
        <div class="pt-2">
            <div class="tab-content" id="myTabContent" style="min-height:0">
                <!-- INDIRIZZO E FATTURAZIONE -->
                <div class="tab-pane fade show active" *ngIf="tab==1">
                    <div class="form-group row">
                        <div class="col-lg-2"><label>Ragione sociale / Nominativo</label></div>
                        <div class="col-lg-10">
                            <input autofocus required type="text" class="form-control form-control-sm" name="name" placeholder="..."
                            #name="ngModel" [(ngModel)]="record.name">
                            <div *ngIf="name.invalid && (name.dirty || name.touched)" class="text-danger">
                                <div *ngIf="name.errors.required"><small>Campo obbligatorio.</small></div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-lg-2"><label>Tipologia</label></div>
                        <div class="col-lg-2">
                            <select *ngIf="type_address" class="form-control form-control-sm" [(ngModel)]="record.id_type" name="id_type" #id_type="ngModel" required>
                                <option *ngFor="let t of type_address" [ngValue]="t.id">{{t.name}}</option>
                            </select>
                            <div *ngIf="id_type.invalid && (id_type.dirty || id_type.touched)" class="text-danger">
                                <div *ngIf="id_type.errors.required"><small>Campo obbligatorio.</small></div>
                            </div>
                        </div>
                        <div class="col-lg-2 px-md-0 text-md-right"><label>Entità</label></div>
                        <div class="col-lg-2">
                            <select class="form-control form-control-sm" [(ngModel)]="record.role" name="role">
                                <option value="">Azienda / Privato</option>
                                <option value="PA">Pubblica Amministrazione</option>
                            </select>
                        </div>
                        <div class="col-lg-2 px-md-0 text-md-right"><label>Stato</label></div>
                        <div class="col-lg-2">
                            <select class="form-control form-control-sm" [(ngModel)]="record.status" name="status" #id_type="ngModel" required>
                                <option value="1">Attivo</option>
                                <option value="2">Cestinato</option>
                                <option value="3">Non attivo</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group row mb-md-1">
                        <div class="col-lg-2">
                            <label>Residente in</label>
                        </div>
                        <div class="col-lg-4">
                            <app-nations [class]="'form-control form-control-sm'" [nation]="record.nation" (onSelect)="onChangeNation($event);"></app-nations>
                        </div>
                        <div class="col-lg-6 text-lg-right">
                            <a  class="btn btn-default btn-sm" (click)="openSearchGPS()" ><i class="fas fa-search-location"></i><span class="d-none d-md-inline-block ml-1">Trova Indirizzo</span></a>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-lg-12">
                            <app-cities name="city" [address]="record.address" [country]="record.country" [city]="record.city"  [zip]="record.zip" (onEntrySelected)="record.address=$event.address;record.city=$event.city;record.country=$event.country;record.zip=$event.zip;"></app-cities>
                        </div>
                    </div>
                    <div class="form-group row mb-1">
                        <div class="col-lg-2">
                            <label>Partita IVA
                                <a  class="btn btn-sm dropdown-toggle" title="Compilazione automatica" data-toggle="dropdown" aria-expanded="true"></a>
                                <div class="dropdown-menu py-0" x-placement="bottom-start">
                                    <small>
                                        <b class="dropdown-item border-bottom bg-light">Compilazione automatica per</b>
                                        <!-- <button type="button" class="dropdown-item" (click)="onChangeTypeVatNumber('1')">Italia</button> -->
                                        <!-- <button type="button" class="dropdown-item" (click)="onChangeTypeVatNumber('2')">Unione Europea</button> -->
                                        <button type="button" class="dropdown-item" (click)="onChangeTypeVatNumber('3')">Extra UE</button>
                                        <button type="button" class="dropdown-item" (click)="onChangeTypeVatNumber('4')">Privato estero</button>
                                    </small>
                                </div>
                            </label>
                        </div>
                        <div class="col-lg-4">
                            <div class="input-group">
                                <app-vat-number [vat_number]="record.vat_number" (onTextChange)="record.vat_number=$event"></app-vat-number>
                                <div class="input-group-append" *ngIf="record.vat_number && record.vat_number.length==11">
                                    <a class="btn btn-default btn-sm" title="Cerca dati sulla Partita IVA nel VIES (VAT Information Exchange System)" (click)="searchByVies()"><i class="fab fa-searchengin"></i><span class="d-none d-md-inline-block ml-1"><small>VIES</small></span></a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 px-md-0 text-md-right">
                            <label>Codice fiscale</label>
                        </div>
                        <div class="col-lg-4">
                            <app-tax-code [showReverseBtn]="true"  [tax_code]="record.tax_code" (onTextChange)="record.tax_code=$event"></app-tax-code>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-lg-2">
                            <label>SdI<i class="fas fa-info-circle ml-2 text-gray help" 
                                title="Il Sistema di Interscambio, detto anche SdI, è il sistema informatico gestito dall’Agenzia delle Entrate 
                                       in grado di ricevere le fatture elettroniche, effettuare i controlli sui dati inseriti e inoltrare le fatture 
                                       ai destinatari finali."></i></label>
                        </div>
                        <div class="col-6 col-lg-2">
                            <div class="input-group">
                                <input type="text" class="form-control form-control-sm" [class.is-invalid]="record.sdi.length && record.sdi.length < 6 && record.sdi.length > 7"
                                name="sdi" [(ngModel)]="record.sdi" (ngModelChange)="record.sdi = $event.toUpperCase()" >
                            </div>
                        </div>
                        <div class="col-lg-4 px-lg-0 text-lg-right">
                            <label>PEC</label>
                        </div>
                        <div class="col-lg-4">
                            <div class="input-group">
                                <input type="email" class="form-control form-control-sm"  name="pec" [(ngModel)]="record.pec"  >
                            </div>
                        </div>
                    </div>
                    <hr/>
                    <div class="row">
                        <div class="col-lg-2"><label>Contatti</label></div>
                        <div class="col-lg-10">
                            <app-contact [list]="record.contacts" [table]="'addresses'" ></app-contact>
                        </div>
                    </div>
                </div>
                <!-- ALTRI DATI -->
                <div class="tab-pane fade show active" *ngIf="tab==2">
                    <div class="row" >
                        <div [class.col-lg-12]="!record.logo" class="col-lg-10">
                            <div class="form-group row mt-2" >
                                <div class="col-lg-2"><label>Logo</label></div>
                                <div class="col-lg-10">
                                    <div class="input-group">
                                        <input type="text" class="form-control form-control-sm" name="logo" [(ngModel)]="record.logo">
                                        <input type="file" style="display: none;" #fileToUpload name="fileToUpload" (change)="handleFileInput($event.target.files)">
                                        <div class="input-group-append">
                                            <a class="btn btn-sm" (click)="fileToUpload.click()"><i class="far fa-image mr-2"></i>Sfoglia</a>
                                        </div>
                                    </div>
                                    <small><i>Inserisci un immagine dal <b>computer</b> oppure scrivi l'URL di un immagine dal <b>web</b>, da utilizzare come logo.</i></small>
                                </div>                            
                            </div>
                            <div class="form-group row mt-2" >
                                <div class="col-lg-2">
                                    <label>Utente</label>
                                </div>
                                <div [class.col-lg-10]="record.user && record.user.role!='guest'" class="col-lg-6">
                                    <div class="input-group">
                                        <input type="text" class="form-control form-control-sm" readonly name="user"  title="ID: {{record.id_user}}" [(ngModel)]="record.username">
                                        <div class="input-group-append">
                                            <a *ngIf="record.id_user==0" class="btn btn-default btn-sm" (click)="searchUser();" title="Lista utenti"><i class="fa fa-user-tie mr-2"></i>Scegli</a>
                                            <a *ngIf="record.id_user>0" class="btn btn-default text-danger btn-sm" (click)="unlinkUser()" title="Disassocia">
                                                <i class="fa fa-unlink"></i>
                                            </a>
                                        </div>
                                    </div>
                                    <small><i>Associa l'anagrafica ad un <b>utente</b> della piattaforma (persona in grado di accedere alla piattaforma mediante delle credenziali).</i></small>   
                                </div>
                                <div *ngIf="record.user && record.user.role=='guest'" class="col-lg-4">
                                    <small>
                                        <div class="small-box mb-0 p-2">
                                            <b>Credenziali d'accesso</b><br/>
                                            <label><i class="fa fa-user"></i></label>&nbsp;<span>{{record.user.username}}</span><br/>
                                            <label><i class="fa fa-key"></i></label>&nbsp;<span>{{record.user.password}}</span>
                                        </div>
                                    </small>
                                </div>                                
                            </div>                           
                            <ng-container *ngIf="record.params">
                                <div class="form-group row mt-2" *ngIf="isModuleEnabled('documents')">
                                    <div class="col-lg-2"><label>Causale di default</label></div>
                                    <div class="col-lg-10">
                                        <select name="description" class="form-control form-control-sm" name="params_document_causal" [(ngModel)]="record.params['default_document_causal']">
                                            <option *ngFor="let d of listDescription" [value]="d.description">{{d.description}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-group row mt-2">
                                    <div class="col-lg-2"><label>Metodo di pagamento di default</label></div>
                                    <div class="col-lg-10">
                                        <select name="description" class="form-control form-control-sm" name="params_methodpayment" [(ngModel)]="record.params['default_methodpayment']">
                                            <option *ngFor="let m of methodpayments" [value]="m.name">{{m.name}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-group row mt-2">
                                    <div class="col-lg-2"><label>Cassa di default</label></div>
                                    <div class="col-lg-10">
                                        <select name="description" class="form-control form-control-sm" name="params_sources" [(ngModel)]="record.params['default_source']">
                                            <option *ngFor="let s of sources" [value]="s.description">{{s.description}}</option>
                                        </select>
                                    </div>
                                </div>
                            </ng-container>                            
                            <div class="form-group row mt-2" *ngIf="isModuleEnabled('financialaccounts')" >
                                <div class="col-lg-2"><label>Conto contabile di default</label></div>
                                <div class="col-lg-10">
                                    <app-financialaccount [id_financialaccount]="record.id_financialaccount" (selected)="record.id_financialaccount=$event['id'];"></app-financialaccount>
                                </div>
                            </div>
                            </div>
                        <div *ngIf="record.logo" class="col-lg-2 d-none d-lg-block">
                            <img class="text-center w-100" src="{{record.logo}}">
                        </div>
                    </div>
                    <div class="row" >
                        <div class="col-lg-12">
                            <hr>
                            <app-customfields [origin]="record" [list]="record.customfields" [table]="'addresses'" [id_table]="record.id" [condition_id]="record.id_type" [condition_field]="'type'" [access]="currentUser.role"></app-customfields>     
                        </div>
                    </div>
                </div>
                <!-- CONTATTI 
                <div class="tab-pane " id="tab4" role="tabpanel" aria-labelledby="tab4-tab">
                    <app-contact [list]="record.contacts" [table]="'addresses'" ></app-contact>
                </div> -->
                <!-- NOTE -->
                <div class="tab-pane fade show active"  *ngIf="record.id && tab==3">
                    <app-notebox [list]="record.notes" [table]="'addresses'" [id_table]="record.id" [id_address]="record.id" *ngIf="record.id"></app-notebox>
                </div>
                <!-- FILE -->
                <div class="tab-pane fade show active"  *ngIf="record.id && tab==4">
                    <app-files [files]="record.files" [directory]="'addresses/'+id"></app-files>
                </div>
            </div>
        </div>

        <!-- SEZIONE INFERIORE -->
        <div class="d-none d-lg-block card bg-light mt-2 mx-1 p-0" *ngIf="id != 0">
            <div class="row">
                <div class="col-1 pt-2 pr-0">
                    <div class="text-center mb-3"><i class="fas fa-link fa-2x text-primary"></i><br>Elementi<br>collegati</div>
                    <small>
                    <div class="nav flex-column nav-pills" id="custom-tabs-four-tab" role="tablist">
                        <ng-container *ngIf="id != 0 && isModuleEnabled('cash-flow')">
                            <a class="nav-link py-1 active" [class.active]="tab_bottom==1" (click)="tab_bottom=1"  >
                                <i class="nav-icon fas fa-dollar-sign"></i><span class="d-none d-lg-inline-block ml-2"><span class="d-none d-lg-block">Movimenti</span></span></a>
                        </ng-container>
                        <ng-container  *ngIf="id != 0 && isModuleEnabled('deadlines')">
                            <a class="nav-link py-1" [class.active]="tab_bottom==2" (click)="tab_bottom=2">
                            <i class="nav-icon far fa-calendar-alt"></i><span class="d-none d-lg-inline-block ml-2"><span class="d-none d-lg-block">Agenda</span></span></a>
                        </ng-container>
                        <ng-container  *ngIf="id != 0 && isModuleEnabled('activities')">
                            <a class="nav-link py-1" [class.active]="tab_bottom==3" (click)="tab_bottom=3">
                                <i class="nav-icon fas fa-tasks"></i><span class="d-none d-lg-inline-block ml-2"><span class="d-none d-lg-block">Attività</span></span></a>
                        </ng-container>
                        <ng-container *ngIf="id != 0 && isModuleEnabled('documents')">
                            <a class="nav-link py-1" [class.active]="tab_bottom==4" (click)="tab_bottom=4">
                                <i class="nav-icon fas fa-file-invoice-dollar"></i><span class="d-none d-lg-inline-block ml-2"><span class="d-none d-lg-block">Documenti</span></span></a>
                           <!--<a class="nav-link py-1" id="custom-tabs-9-tab" data-toggle="pill" href="#custom-tabs-9" (click)="activeTab=true"
                                role="tab" aria-controls="custom-tabs-9" aria-selected="false">
                                <span class="d-none d-lg-inline-block ml-4">&#10551; B<span class="d-none d-lg-inline">eni</span></span></a>-->
                        </ng-container>
                        <ng-container *ngIf="id != 0 && isModuleEnabled('installations')">
                            <a class="nav-link py-1" [class.active]="tab_bottom==5" (click)="tab_bottom=5">
                                <i class="nav-icon fas fa-barcode"></i><span class="d-none d-lg-inline-block ml-2"><span class="d-none d-lg-block">Seriali</span></span></a>
                        </ng-container>
                        <ng-container *ngIf="id != 0 && isModuleEnabled('shops')">
                            <a class="nav-link py-1" [class.active]="tab_bottom==6" (click)="tab_bottom=6">
                                <i class="fas fa-store-alt"></i><span class="d-none d-lg-inline-block ml-2"><span class="d-none d-lg-block">Sedi</span></span></a>
                        </ng-container>                       
                        <ng-container *ngIf="id != 0 && isModuleEnabled('contracts')">
                            <a class="nav-link py-1" [class.active]="tab_bottom==7" (click)="tab_bottom=7">
                                <i class="nav-icon fas fa-file-signature"></i><span class="d-none d-lg-inline-block ml-2"><span class="d-none d-lg-block">Contratti</span></span></a>
                        </ng-container>
                        <ng-container *ngIf="id != 0 && isModuleEnabled('notifications')">
                            <a class="nav-link py-1" [class.active]="tab_bottom==8" (click)="tab_bottom=8">
                                <i class="nav-icon far fa-paper-plane"></i><span class="d-none d-lg-inline-block ml-2"><span class="d-none d-lg-block">Notifiche</span></span></a>
                        </ng-container>
                    </div>
                </small>
                </div>
                <div class="col-11">
                    <div class="tab-content" id="custom-tabs-four-tabContent">
                        <!-- TAB: Movimenti -->
                        <div *ngIf="isModuleEnabled('cash-flow') && tab_bottom==1" class="tab-pane active" >
                            <app-cash-flow [id_address]="record.id" [mode]="'embedded'" *ngIf="id != 0"></app-cash-flow>
                        </div>
                        <!-- TAB: Scadenze -->
                        <div *ngIf="isModuleEnabled('deadlines') && tab_bottom==2" class="tab-pane active" >
                            <app-deadlines [viewMode]="'table'" [filter_box]="false" [default_filterdate]="100" [no_dateend]="true" [id_address]="record.id" [mode]="'embedded'" *ngIf="id != 0"></app-deadlines>
                        </div>
                        <!-- TAB: Attività -->
                        <div *ngIf="isModuleEnabled('activities') && tab_bottom==3" class="tab-pane active" >
                            <app-activities-list [default_filterdate]="100" [id_address]="record.id" [mode]="'embedded'" *ngIf="id != 0"></app-activities-list>
                        </div>
                        <!-- TAB: Documenti -->
                        <div *ngIf="isModuleEnabled('documents') && tab_bottom==4" class="tab-pane active" >
                            <ul class="nav nav-tabs mx-1" id="myTab" role="tablist">
                                <li class="nav-item" >
                                    <a class="nav-link active" id="profile-tab" data-toggle="tab" href="#dd-1" role="tab" aria-controls="profile" aria-selected="false">Documenti</a>
                                </li>
                                <li class="nav-item" >
                                    <a class="nav-link" id="profile-tab" data-toggle="tab" href="#dd-2" role="tab" aria-controls="profile" aria-selected="false">Beni</a>
                                </li>
                            </ul>
                            <div class="tab-content" id="custom-tabs-four-tabContent">
                                <div  class="tab-pane active" id="dd-1" role="tabpanel" aria-labelledby="dd-1">
                                    <app-documentlist [filterbox]="false" [type]="0" [id_address]="record.id" [mode]="'embedded'" *ngIf="id != 0" (ids)="ids_documents=$event"></app-documentlist>
                                </div>
                                <div  class="tab-pane" id="dd-2" role="tabpanel" aria-labelledby="dd-2">
                                   <app-documentinventories [ids_documents]="ids_documents" [mode]="'embedded'" *ngIf="id != 0"></app-documentinventories>
                                </div>
                            </div>
                        </div>
                        
                        <!-- TAB: Beni (attivata solo su click perchè rallenta) -->
                        <!--<div *ngIf="isModuleEnabled('documents')" class="tab-pane" id="custom-tabs-9" role="tabpanel" aria-labelledby="custom-tabs-9-tab">
                            <ng-container *ngIf="activeTab">                         
                                <app-documentinventories [ids_documents]="ids_documents" [paging_count]="24" [mode]="'embedded'" *ngIf="id != 0"></app-documentinventories>
                            </ng-container>                 
                        </div>-->
                        <!-- TAB: Sedi -->
                        <div *ngIf="isModuleEnabled('shops') && tab_bottom==6" class="tab-pane active" >
                            <app-shops [id_address]="record.id" [mode]="'embedded'" *ngIf="record.id != 0" (firstIdShop)="first_id_shop=$event"></app-shops>
                        </div>
                        <!-- TAB: Prodotti -->
                        <div *ngIf="isModuleEnabled('installations') && tab_bottom==5" class="tab-pane active" >
                            <app-serialshop [id_address]="id" [id_shop]="first_id_shop" [mode]="'embedded'" *ngIf="id != 0"></app-serialshop>
                        </div>
                        <!-- TAB: Contratti -->
                        <div *ngIf="isModuleEnabled('contracts') && tab_bottom==7" class="tab-pane active" >
                            <app-contracts [id_address]="record.id" [mode]="'embedded'" *ngIf="id != 0"></app-contracts>
                        </div>
                        <!-- TAB: Notifiche -->
                        <div *ngIf="isModuleEnabled('notifications') && tab_bottom==8" class="tab-pane active" >
                            <app-notificationlogs [id_address]="record.id" [mode]="'embedded'" *ngIf="id != 0"></app-notificationlogs>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
    </form>
    <app-toolbarmodel [model]="this" [showSaveAndClose]="true"></app-toolbarmodel>
</app-window>


<ng-template #modalAddUser>
    <div class="modal-body">
        <h4 class="text-center text-primary">Vuoi creare un utente collegato a questa anagrafica<i class="fas fa-question"></i></h4>
        <hr>
        <div class="form-group">
            <label>Nome</label>
            <input type="text" class="form-control form-control-sm" name="newuser_name" [(ngModel)]="newuser.name">
            <br>
            <label>Username</label>
            <input type="text" class="form-control form-control-sm" name="newuser_username" [(ngModel)]="newuser.username">
            <br>
            <label>Password</label>
            <input type="password" class="form-control form-control-sm" name="newuser_password" [(ngModel)]="newuser.password">
            <br>
            <label>Ruolo</label>
            <select class="form-control form-control-sm" name="newuser_role" [(ngModel)]="newuser.role">
                <option value="admin" *ngIf="currentUser.isAdmin() || currentUser.isSuperUser()">Amministratore</option>
                <option value="manager" *ngIf="currentUser.isAdmin() || currentUser.isSuperUser()">Manager</option>
                <option value="agent">Agente</option>
                <option value="external">Partner</option>
                <option value="guest">Ospite</option>
            </select>
            <small>
                <i *ngIf="newuser.role=='admin'"     >*<b>Amministratore</b>: ha accesso a tutte le sezioni ed autorizzazioni complete.</i>
                <i *ngIf="newuser.role=='manager'"   >*<b>Manager</b>: ha accesso solo alle sezioni assegnategli dall'Amministratore ed autorizzazioni complete.</i>
                <i *ngIf="newuser.role=='agent'"     >*<b>Agente</b>: ha accesso solo alle sole sezioni assegnategli dall'Amministratore ed autorizzazioni limitate (visualizzazione, inserimento, modifica).</i>
                <i *ngIf="newuser.role=='external'"  >*<b>Partner</b>: ha accesso limitato solo alle sezioni assegnategli dall'Amministratore ed autorizzazioni minime (visualizzazione, inserimento).</i>
                <i *ngIf="newuser.role=='guest'"     >*<b>Ospite</b>: non ha accesso alla piattaforma di gestione ma solo eventualmente a quella riservata ai clienti registrati.</i>
            </small>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-sm btn-secondary" (click)="closeModalAddUser();">No, grazie</button>
        <button class="btn btn-sm btn-success" (click)="createUser();">Crea utente</button>
    </div>
</ng-template>


<ng-template #modalVies>
    <div class="modal-header">
        <b>Ricerca nel VIES</b>
        <div class="card-tools">
            <button type="button" class="btn btn-tool" (click)="closeModalVies()" >
                <i class="fas fa-times"></i>
            </button>
        </div>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-12 text-center my-3">
                <h2>{{record.vat_number}}</h2>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-lg-4 text-lg-right">
                <span>Denominazione</span>
            </div>
            <div class="col-lg-8">
                <b>{{viesResult['name']}}</b>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4 text-lg-right">
                <span>Indirizzo</span>
            </div>
            <div class="col-lg-8">
                <b>{{viesResult['address']}}</b>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-sm btn-success" (click)="useVies(false)">Utilizza questi dati</button>
        <button class="btn btn-sm btn-primary" (click)="useVies(true)">Posiziona su mappa</button>
        <button class="btn btn-sm btn-secondary" (click)="closeModalVies()">Chiudi</button>
    </div>
</ng-template>


<ng-template #modalIntroNewRecord>
    <div class="modal-header">
        <b>Nuova anagrafica</b>
        <div class="card-tools">
            <button type="button" class="btn btn-tool" (click)="closeModalIntro();" >
                <i class="fas fa-times"></i>
            </button>
        </div>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-12">
                <div class="row m-5">
                    <div class="col-12 text-center">
                        <i class="fas fa-hat-wizard text-gray fa-2x mr-2"></i>Inserisci la partita IVA, proverò a rintracciare i suoi dati sul VIES
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-3 text-center text-lg-right"><label>Partita IVA</label></div>
                    <div class="col-lg-6 text-center text-lg-left">
                        <div class="input-group input-group-sm">
                            <input type="text" class="form-control form-control-sm" name="searchToViesInput" [(ngModel)]="record.vat_number" (keypress.enter)="searchByVies()" >
                            <div class="input-group-append">
                                <a class="btn btn-default btn-secondary" (click)="searchByVies()">Cerca</a>
                            </div>
                        </div>
                        
                    </div>
                </div>
                <a class="mt-5 btn btn-block btn-primary btn-xl" (click)="closeModalIntro();">No, grazie</a>
            </div>
        </div>
    </div>
</ng-template>