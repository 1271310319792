<app-window [title]="title" >
    <form role="form" id="detailForm">
        <div class="row">
            <div class="col-lg-6">
                <div class="form-group form-group-sm">
                    <label>Etichetta</label>
                    <input type="text" class="form-control form-control-sm" placeholder="..." name="title"  [(ngModel)]="record.title" >
                </div>
            </div>
            <div class="col-lg-6">
                <div class="form-group form-group-sm">
                    <label>Nome</label>
                    <input type="text" class="form-control form-control-sm" placeholder="..." name="name"  [(ngModel)]="record.name" >
                </div>
            </div>
            <div class="col-lg-12">
                <div class="form-group form-group-sm">
                    <label>Descrizione</label>
                    <input type="text" class="form-control form-control-sm" placeholder="..." name="description"  [(ngModel)]="record.description" >
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <h5>Gestione permessi sui moduli</h5>
                <table class="table table-sm">
                    <ng-container *ngFor="let m of record.permissions">
                        <tr class="bg-light">
                            <td><b>{{m.modulename}}</b></td>
                            <td class="text-right">
                                <!-- <input type="checkbox" (change)="checkAll(m.modulename)" [checked]="checkAll(m.modulename)==true"/> -->
                                <button class="btn btn-xs btn-default" title="Tutti"><i class="fa fa-check-double text-primary" (click)="checkAll(m.modulename)"></i></button>                     
                            </td>
                        </tr>
                        <tr *ngFor="let p of m.permissions">
                            <td><small>{{p.description}}</small></td>
                            <td class="text-right">
                                <input class="mr-2" type="checkbox" (change)="p.value==true?p.value=false:p.value=true" [checked]="p.value==true"/>
                                <!-- <i class="fa fa-check text-success" *ngIf="p.value" (click)="p.value=false"></i>
                                <i class="fa fa-times text-danger" *ngIf="!p.value" (click)="p.value=true"></i> -->
                            </td>
                        </tr>
                    </ng-container>
                </table>
            </div>
        </div>
    </form>
    <app-toolbarmodel [model]="this" [showSaveAndClose]="true"></app-toolbarmodel>
</app-window>