<app-window [title]="'Dettaglio progetto'">
    <form role="form" id="detailForm">
        
        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item ">
                <a class="nav-link active" id="profile-tab" data-toggle="tab" href="#tab1Pro" role="tab" aria-controls="profile" aria-selected="false">
                    <i class="far fa-clipboard"></i><span class="d-none d-sm-inline-block ml-2">Generale</span></a>
            </li>
            <li class="nav-item ">
                <a class="nav-link" id="profile-tab" data-toggle="tab" href="#tab4Pro" role="tab" aria-controls="profile" aria-selected="false">
                    <i class="fas fa-sign-out-alt"></i><span class="d-none d-sm-inline-block ml-2">Ricavi</span></a>
            </li>
            <li class="nav-item ">
                <a class="nav-link" id="profile-tab" data-toggle="tab" href="#tab5Pro" role="tab" aria-controls="profile" aria-selected="false">
                    <i class="fas fa-sign-in-alt"></i><span class="d-none d-sm-inline-block ml-2">Costi</span></a>
            </li>
            <li class="nav-item ">
                <a class="nav-link" id="profile-tab" data-toggle="tab" href="#tab6Pro" role="tab" aria-controls="profile" aria-selected="false">
                    <i class="far fa-hdd"></i><span class="d-none d-sm-inline-block ml-2">Files <span class="badge badge-warning" *ngIf="record.files && record.files.length>0">{{record.files.length}}</span></span></a>
            </li>
        </ul>
    
        <div class="p-2" >
            <div class="tab-content" id="myTabContent">
                <!-- GENERALE -->
                <div class="tab-pane fade show active" id="tab1Pro" role="tabpanel" aria-labelledby="tab2-tab">
                    <div class="form-group row">
                        <div class="col-2"><label>Data</label></div>
                        <div class="col-4">
                            <input type="date" class="form-control form-control-sm" name="date" [(ngModel)]="record.date">
                        </div>
                        <div class="col-2 text-right"><label>Fase</label></div>
                        <div class="col-4">
                            <select class="form-control form-control-sm" name="phase" [(ngModel)]="record.phase">
                                <option value="1">Preventivato</option>
                                <option value="2">In corso</option>
                                <option value="3">Concluso</option>
                                <option value="4">Annullato</option>
                                
                            </select>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-lg-2"><label>Anagrafica</label></div>
                        <div class="col-12 col-lg-10">
                            <div class="small-box bg-light mb-0">
                                <app-selectfieldcustom (OnExplore)="selectAddress()" (OnRecordSelected)="OnSelectAddress($event)" (OnRecordView)="OnViewAddress($event)"
                                    [showViewButton]="true"
                                    [showSearchButton]="true"
                                    [recordSelected]="record.addressItem"
                                    [service]="addressesService"
                                    [fieldRender]="'name'"
                                    [class]="'form-control form-control-sm'"></app-selectfieldcustom>
                                <div class="p-1" *ngIf="record.addressItem && record.addressItem.id>0">
                                    <small>
                                        <div class="row">
                                            <div class="col-lg-6">
                                                <small>
                                                    <ng-container *ngIf="record.addressItem.address || record.addressItem.city">
                                                        <b>INDIRIZZO:</b><br>
                                                        {{record.addressItem.address}}<br>
                                                        {{record.addressItem.zip}} {{record.addressItem.city}} {{record.addressItem.country}}<br>
                                                    </ng-container>
                                                    <span *ngIf="record.addressItem.tax_code">COD. FISC.: {{record.addressItem.tax_code}}<br></span>
                                                    <span *ngIf="record.addressItem.vat_number">P.IVA: {{record.addressItem.vat_number}}</span>
                                                </small>
                                            </div>
                                            <div class="col-lg-6 border-left" *ngIf="record.addressItem.contacts.length > 0">
                                                <small>
                                                    <b>CONTATTI:</b><br>
                                                    <span *ngFor="let c of record.addressItem.contacts">{{c.value}}<br></span>
                                                </small>
                                            </div>
                                        </div>
                                    </small>
                                    <div class="icon"><i class="fa fa-user"></i></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-2"><label>Titolo</label></div>
                        <div class="col-7">
                            <input type="text"  class="form-control form-control-sm" name="name" [(ngModel)]="record.name">
                        </div>
                        <div class="col-1 text-lg-right"><label>Importo</label></div>
                        <div class="col-2">
                            <input type="text"  class="form-control form-control-sm" name="amount" [(ngModel)]="record.amount">
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-2"><label>Descrizione</label></div>
                        <div class="col-10">
                            <textarea  class="form-control form-control-sm" name="description" [(ngModel)]="record.description"></textarea>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-2"><label>Note</label></div>
                        <div class="col-10">
                            <textarea  class="form-control form-control-sm" name="note" [(ngModel)]="record.note"></textarea>
                        </div>
                    </div>
                </div>
                <!-- RICAVI -->
                <div class="tab-pane fade" id="tab4Pro" role="tabpanel" aria-labelledby="tab2-tab">
                        <app-documentlist #incominglist [filterbox]="false" [type]="1" [mode]="'embedded'" [id_table]="record.id" [table]="'project'"></app-documentlist>
                        <button type="button" class="btn btn-sm btn-success" (click)="addDocument(1);"><i class="fa fa-plus mr-2"></i>Aggiungi</button>
                        <button type="button" class="btn btn-sm btn-primary ml-2" (click)="linkDocument(1);"><i class="fa fa-link mr-2"></i>Collega documento</button>
                </div>
                <!-- COSTI -->
                <div class="tab-pane fade" id="tab5Pro" role="tabpanel" aria-labelledby="tab2-tab">
                    <app-documentlist #costlist [filterbox]="false" [type]="2" [mode]="'embedded'" [id_table]="record.id" [table]="'project'"></app-documentlist>
                    <button type="button" class="btn btn-sm btn-success" (click)="addDocument(2);"><i class="fa fa-plus mr-2"></i>Aggiungi</button>
                    <button type="button" class="btn btn-sm btn-primary ml-2" (click)="linkDocument(2);"><i class="fa fa-link mr-2"></i>Collega documento</button>
                </div>
                <!-- FILE -->
                <div class="tab-pane fade" id="tab6Pro" role="tabpanel" aria-labelledby="tab1-tab">
                    <app-files [files]="record.files" [directory]="'projects/'+id"></app-files>
                </div>
            </div>
        </div>
    </form> 
</app-window>
<app-toolbarmodel [model]="this"></app-toolbarmodel>
 