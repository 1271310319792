<div class="row my-1">
    <div class="col-lg-2 pr-lg-0">
      <div class="row">
          <div class="col-4 col-lg-12">
              <div class="card">
                  <small>
                    <ul class="card list-group p-1">
                          <li class="list-group-item pointer" [class.active]="filter_id_inventory.value==i.id" *ngFor="let i of inventories" (click)="filter_id_inventory.value=i.id;getItems()">{{i.name}}</li>
                          <div *ngIf="inventories.length>2" class="icon"><i class="fas fa-boxes"></i></div>
                    </ul>
                  </small>
              </div>
          </div>
      </div>
    </div>
    <div class="col-lg-10">
      <div class="card card-outline">
          <div class="p-0">
              <div class="row">
                  <div class="col-7">
                      <div class="input-group input-group-info input-group-sm p-1">
                          <input type="text" #table_search name="table_search" class="form-control form-control-sm" placeholder="cerca per nome prodotto o seriale..." [(ngModel)]="filter_search.value" (keyup.enter)="getItems()">
                          <div class="input-group-append ">
                              <button type="submit" class="btn btn-default text-primary"  title="avvia ricerca" (click)="getItems()"><i class="fas fa-search"></i></button>
                              <button class="btn btn-default text-danger" *ngIf="filter_search.value!=''"  title="annulla ricerca" (click)="filter_search.value='';getItems();"><span id="searchclear" class="fas fa-times"></span></button>
                          </div>
                      </div>
                  </div>
                  <div class="col-5" >
                      <button *ngIf="filter_status.value == '1'" (click)="trash()" [disabled]="form.value.id.length == 0" class="btn btn-danger btn-xs float-right m-1 "><i class="far fa-trash-alt"></i></button>
                      <button *ngIf="filter_status.value == '2'" (click)="delete()" [disabled]="form.value.id.length == 0" class="btn btn-danger btn-xs float-right m-1 "><i class="fas fa-trash"></i></button>
                      <button *ngIf="filter_status.value == '2'" (click)="undo()" [disabled]="form.value.id.length == 0" class="btn btn-secondary btn-xs float-right m-1 "><i class="fas fa-undo"></i></button>
                      <button  (click)="export()" class="btn btn-secondary btn-xs float-right m-1 "><i class="fas fa-file-export"></i></button>
                      <button *ngIf="id_product==0" (click)="unload()" class="btn btn-danger btn-xs float-right m-1"><i class="fas fa-arrow-circle-down mr-2"></i>Scarica</button>
                      <button *ngIf="id_product==0" (click)="load()" class="btn btn-success btn-xs float-right m-1"><i class="fas fa-arrow-circle-up mr-2"></i>Carica</button>
                      <!--<button class="btn btn-success btn-xs float-right m-1" (click)="fastout()"><i class="fas fa-plus"></i></button>-->
                  </div>
              </div>

              <div *ngIf="!list || list.length==0" class="m-5 text-center text-secondary">
                <i class="icon fas fa-info-circle mr-2"></i><i>{{messageStatus}}</i>
                
            </div>
            <form *ngIf="list && list.length>0" [formGroup]="form">
                  <table class="table table-nowrap  table-sm table-head-fixed table-hover m-0 text-sm">
                      <thead class="bg-light">
                          <th>
                              <div class="icheck-primary d-inline mr-3">
                                  <input #checkboxselectall  type="checkbox" title="Seleziona tutti"
                                  (change)="onChangeAll($event.target.checked)" />
                              </div>
                              Data
                              <button class="btn btn-tool" (click)="switchOrdering('i.date')">
                                  <i class="fas fa-sort {{classOrdering('i.date')}}"  ></i>
                              </button>
                          </th>
                          <th>
                              Prodotto
                              <button class="btn btn-tool" (click)="switchOrdering('i.reference')">
                                  <i class="fas fa-sort {{classOrdering('i.reference')}}"  ></i>
                              </button>
                          </th>
                          <th>Anagrafica</th>
                          <th class="d-none d-sm-table-cell">
                              QT
                              <button class="btn btn-tool" (click)="switchOrdering('i.typedocument')">
                                  <i class="fas fa-sort {{classOrdering('i.typedocument')}}"  ></i>
                              </button>
                          </th >
                          <th class="d-none d-sm-table-cell">
                              Prezzo
                          </th>
                          <th>
                              Magazzino
                              <button class="btn btn-tool" (click)="switchOrdering('i.inventoryname')">
                                  <i class="fas fa-sort {{classOrdering('i.amount')}}"  ></i>
                              </button>
                          </th>
                          <th></th>
                      </thead>
                      <tbody>
                      
                          <!-- elemento ripetuto -->
                          <tr *ngFor="let inventory of list">
                              <td>
                                  <div class="icheck-primary d-inline mr-3">
                                      <input type="checkbox" #checkrecord id_record="{{inventory.id}}"
                                      (change)="onChange(inventory.id, $event.target.checked)" [checked]="checkedAll"
                                      />
                                  </div>
                                  <span *ngIf="inventory.type==1" class="badge badge-success"><i class="fa fa-arrow-circle-up"></i></span>
                                  <span *ngIf="inventory.type==2" class="badge badge-danger"><i class="fa fa-arrow-circle-down"></i></span>
                                  <a [class.trashed]="inventory.status == 2" (click)="openDetail(inventory)"   title="{{inventory.date | date:'EEEE'}}">
                                      {{inventory.date | date:'dd/M/yyyy'}}
                                  </a>
                              </td>
                              <td>
                                  {{ inventory.description }}<span *ngIf="inventory.sn">&nbsp;<div class="badge badge-info">{{ inventory.sn }}</div></span>
                              </td>
                              <td><ng-container *ngIf="inventory.addressItem">{{inventory.addressItem.name}}</ng-container></td>
                              <td class="d-none d-sm-table-cell">
                                  <span *ngIf="inventory.type==1">+</span>
                                  <span *ngIf="inventory.type==2">-</span>
                                  {{inventory.quantity}}
                              </td>
                              <td class="d-none d-sm-table-cell">
                                  <span *ngIf="inventory.type==1">-</span>
                                  <span *ngIf="inventory.type==2">+</span>
                                  {{inventory.netprice*inventory.quantity*(1+inventory.tax/100)*(1-inventory.discount/100) | currency:"&euro; "}}
                              </td>
                              <td  >{{inventory.inventoryname}}</td>
                              <td>
                                  <button type="button" (click)="openDetail(inventory)"   title="Modifica / Vedi" class="btn btn-secondary btn-xs m-1 float-right">
                                      <i class="fas fa-pencil-alt"></i><span class="d-none d-sm-inline-block">&ensp;</span></button>                                  
                                  <button type="button" *ngIf="inventories && inventories.length>1" (click)="move(inventory)" title="Sposta gli elementi selezionati in un altro magazzino"  class="btn btn-secondary btn-xs float-right m-1">
                                    <i class="fas fa-exchange-alt"></i></button>
  
                              </td>
                          </tr>
                      </tbody>
                      <tfoot>
                          <tr *ngIf="total!=null">
                              <td>Totale</td>
                              <td></td>
                              <td></td>
                              <td><span [class.text-success]="total.quantity>0" [class.text-danger]="total.quantity <=0">{{total.quantity}}</span></td>
                              <td><span [class.text-success]="total.amount>0" [class.text-danger]="total.amount <=0">{{total.amount | currency:"&euro; "}}</span></td>
                              <td></td>
                          </tr>
                      </tfoot>
                  </table>
              </form>
              <div class="card-footer clearfix py-1">
                  <app-pagination [model]="this" ></app-pagination>
              </div>
          </div>
      </div>
    </div>
  </div>