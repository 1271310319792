<div class="container-fluid ">
    <div class="row p-lg-1">
        <div class="col-lg-9 px-0 pr-lg-2 mb-1">
            <app-goods [descriptionReadonly]="true" [viewmode]="2" [list]="list" [showOpenDocuments]="true" (onOpenDocuments)="openDocuments()" (listUpdate)="list=$event;calculateTotal();"></app-goods>
        </div>
        <div class="col-lg-3 px-0">
            <div *ngIf="not_in_stock" class="small-box  mb-1 bg-warning d-none d-lg-block">
                <div class="inner">
                  <h6>Ci sono prodotti da riordinare!</h6>
                </div>
                <div class="icon">
                    <i class="fas fa-exclamation"></i>
                </div>
            </div>
            <div *ngIf="not_in_this_stock" class="small-box  mb-1 bg-warning d-none d-lg-block">
                <div class="inner">
                  <h6>Ci sono prodotti da richiedere da altro magazzino!</h6>
                </div>
            </div>
            <div *ngIf="rt" class="small-box  mb-1 bg-info d-none d-lg-block ">
                <div class="inner">
                  <h6>Registrato di cassa collegato.</h6>
                  <h4>{{rt}}</h4>
                </div>
                <div class="icon">
                  <i class="fas fa-cash-register "></i>
                </div>
                <span (click)="connectCashRegister()" class="small-box-footer pointer"><i class="fa fa-sync mr-2"></i>Aggiorna</span>
                
                
            </div>
            <ng-container *ngIf="c1connector_enable">
                <div *ngIf="!rt" class="small-box mb-1 bg-danger d-none d-lg-block ">
                    <div class="inner">
                    <h5>Nessun registratore di cassa collegato!</h5>
                    </div>
                    <div class="icon">
                    <i class="fas fa-exclamation-triangle"></i>
                    </div>
                    <span (click)="connectCashRegister()"class="small-box-footer pointer"><i class="fa fa-sync mr-2"></i>Aggiorna</span>
                </div>
                <div *ngIf="!rt" class="main-footer fixed-bottom bg-danger text-center d-xs-block d-lg-none" style="z-index:1031">
                    <span (click)="connectCashRegister()">Nessun registratore di cassa collegato !<i class="fa fa-sync ml-3"></i></span>
                </div>
            </ng-container>
            <div class="card p-2 bg-light">
                <!-- <button class="btn btn-info btn-sm" (click)="showKeyboard==true ? showKeyboard=false : showKeyboard=true"><i class="fa fa-keyboard mr-1"></i>Tastiera</button> -->
                <button *ngIf="list.length!=0" class="btn btn-danger btn-sm mb-4" (click)="reset(true)"><i class="fa fa-times mr-2"></i>Azzera</button>
                <b>Riepilogo documento</b>
                <hr>
                <div class="form-group row mb-0">
                    <div class="col-6"><span>Imponibile</span></div>
                    <div class="col-6 text-right">{{net_total | currency:"&euro;"}}</div>
                </div>
                <div class="form-group row mb-0">
                    <div class="col-6"><span>Sconto</span></div>
                    <div class="col-6 text-right">{{discount_total | currency:"&euro;"}}</div>
                </div>
                <div class="form-group row  mb-0">
                    <div class="col-6"><span>IVA</span></div>
                    <div class="col-6 text-right">{{tax_total | currency:"&euro;"}}</div>
                </div>
                <hr>
                <div class="form-group row mb-0">
                    <div class="col-6"><b>Totale</b></div>
                    <div class="col-6 text-right"><h5 class="text-success">{{total | currency:"&euro;"}}</h5></div>
                    <div class="col-6"><i>Da pagare</i></div>
                    <div class="col-6 text-right"><h5 class="text-danger">{{total_paid | currency:"&euro;"}}</h5></div>
                </div>
                <div class="row">
                    <div class="col-lg-12 mt-2">

                        <button [disabled]="list.length==0" class="btn btn-success btn-block" style="height:96px;" (click)="confirm()">
                            <i class="fa-2x fa fa-check"></i><br/><b>Conferma operazione</b></button>
                        <!--
                        <button [disabled]="list.length==0" class="btn btn-block btn-primary" (click)="receipt()">
                            <i class="fa fa-print mr-2"></i>
                            <span *ngIf="not_in_stock || not_in_this_stock">Ordine cliente e </span>Scontrino (F2)
                        </button>
                        <button [disabled]="list.length==0" class="btn btn-block btn-success" (click)="billing()">
                            <i class="fa fa-file-invoice-dollar mr-2"></i>
                            <span *ngIf="not_in_stock || not_in_this_stock">Ordine cliente e </span>Fattura (F4)
                        </button>
                        -->
                        <button [disabled]="list.length==0" class="btn btn-block btn-secondary" (click)="openGenerateDocumentModal()">
                            <i class="fa fa-file-alt mr-2"></i>
                            Altri documenti (F8)
                        </button>
                    </div>
                </div>
                <!-- <button (click)="showModalMethodpayment()" class="btn btn-outline-danger btn-block mt-2"><i class="fa fa-wrench mr-2"></i>(APRI METODI DI PAGAMENTO)</button> -->
            </div>
        </div>
        <div class="col-12 d-xs-block d-lg-none pb-5"></div>
    </div>
</div>

<app-keyboard *ngIf="showKeyboard" (data)="addFromKeyboard($event);" (onDigit)="goods.searchproductComponent.focusSearch()" [id_rep]="id_product_rep"></app-keyboard>


<ng-template #modalUnload>
    <div class="modal-header bg-light">
        <b>Indica la causale dello scarico</b>
        <div class="card-tools">
            <button type="button" class="btn btn-tool" (click)="closeUnloadDescription()" >
                <i class="fas fa-times"></i>
            </button>
        </div>
    </div>
    <div class="modal-body">
        <input type="text" class="form-control" name="description_unload" [(ngModel)]="description_unload">
    </div>
    <div class="row">
            <div class="col-md-6 ">
                <button class="btn btn-block btn-sm  btn-success" (click)="unload();closeUnloadDescription();"><i class="fa fa-1x fa-download"></i><br/>Ok</button>
            </div>
            <div class="col-md-6 text-right">
                <button class="btn btn-block btn-sm  btn-secondary" (click)="closeUnloadDescription()"><i class="fa fa-1x fa-times"></i><br/>Annulla</button>
      
            </div>
    </div>
</ng-template>

<ng-template #modalPrint>
    <div class="modal-body">
        <h5>Stampa in corso...</h5>
        <div class="row">
            <div class="col-12">
                <button class="btn btn-secondary" (click)="closePrintModal()">Annulla</button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #modalConfirmReceipt>
    <div class="modal-header">
        <b>Conferma scontrino</b>
        <div class="card-tools">
            <button type="button" class="btn btn-tool" (click)="closeConfirmReceiptModal()" >
                <i class="fas fa-times"></i>
            </button>
        </div>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-12">
                <input type="text" name="reference" class="form-control form-control-sm" placeholder="indicare il numero di scontrino..." [(ngModel)]="receiptDocument['reference']" />
            </div>
            <div class="col-12">
                <button class="btn btn-success" (click)="ConfirmReceiptModal()">Ok</button>
                <button class="btn btn-secondary" (click)="closeConfirmReceiptModal()">Annulla</button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #modalGenerateDocument>
    <div class="modal-header">
        <b>Genera documento</b>
        <div class="card-tools">
            <button type="button" class="btn btn-tool" (click)="closeGenerateDocumentModal()" >
                <i class="fas fa-times"></i>
            </button>
        </div>
    </div>
    <div class="modal-body">
        <button [disabled]="list.length==0" class="btn btn-block btn-primary m-1" (click)="receipt()">
            <i class="fa fa-print fa-2x mr-2"></i><br/>
            <span *ngIf="not_in_stock || not_in_this_stock">Ordine cliente e </span>Stampa scontrino (F2)
        </button>
        <button [disabled]="list.length==0" class="btn btn-block btn-success m-1" (click)="billing()">
            <i class="fa fa-file-invoice-dollar fa-2x mr-2"></i><br/>
            <span *ngIf="not_in_stock || not_in_this_stock">Ordine cliente e </span>Crea fattura (F4)
        </button>
        <button class="btn btn-block btn-success m-1" (click)="createNonFiscalDocument()">
            <i class="fa fa-2x fa-file"></i><br/>Crea documento non fiscale</button>
        <button class="btn btn-block btn-danger m-1" (click)="openUnloadDescription()">
            <i class="fa fa-2x fa-arrow-circle-down"></i><br/>Scarica senza documento</button>
        <button class="btn btn-block btn-secondary m-1" (click)="openOrderClient()">
            <i class="fa fa-2x fa-file-contract"></i><br/>Crea ordine cliente</button>
        <button class="btn btn-block bg-lightblue m-1" (click)="createDDT()">
            <i class="fa fa-2x fa-file-export"></i><br/>Crea DDT di trasferimento</button>
    </div>
</ng-template>