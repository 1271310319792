import { Injectable } from '@angular/core';
import {Company} from "./include/structures";
import { HttpClient } from '@angular/common/http';
import { Title } from '@angular/platform-browser';
import { Globals } from './globals';
import { SelectconfigfileComponent } from './common/selectconfigfile/selectconfigfile.component';

@Injectable()
export class Config{

    //variabili generali
    id="";
    softwarename="";
    softwareversion="";
    softwareicon="";
    softwarebackground="";
    softwarecolor="";
    license="";
    company:Company=new Company();
    favIcon: HTMLLinkElement = document.querySelector('#appIcon');
    serverUrl="";
    copyright=true;
    expired_license="";
    config:any; //file di configurazione
    changeLog:any; //contenuto file con il changeLog


     constructor(
        private http:HttpClient,
        private titleService: Title
  
      ){}

    setConfig(config,setTitle){
            this.config=config;
            
            if(Array.isArray(this.config['serverUrl'])){
              this.config['serverUrl']=this.config['serverUrl'][0];
            }
            this.serverUrl=this.config['serverUrl'];
            Globals.ws.base=this.config['serverUrl']+"/api.php";
            this.softwareversion=this.config['version'];
            this.softwarename=this.config['name'];
            this.softwarebackground=this.config['background'];
            this.softwarecolor=this.config['color'];
            this.license=this.config['license'];
            this.softwareicon=this.config['icon'];
            this.company=this.config['company'];
            let allowRole=this.config['allowRole']==undefined?['admin']:this.config['allowRole'];
            this.copyright=this.config['copyright']==undefined?this.config['copyright']:true;
            this.id=this.config['id']==undefined?this.config['name']:this.config['id'];
            Globals.access.allowRole=allowRole;
            
            //if(this.config['favicon'])
              //this.favIcon.href=this.config['favicon'];
            if(setTitle)
              this.titleService.setTitle(this.softwarename);
            
            /*configurazioni*/
            Globals.access.prefix=this.softwarename;
    }
     
    loadConfig(onComplete:Function,setTitle=true){
        this.http.get(Globals.rootpath+"config.json").subscribe((value)=>{
          
          
          if(Array.isArray(value)){
            Globals.modal.showModal(SelectconfigfileComponent,[
              {"name":"configs","value":value}
            ],(instance)=>{
              let config=instance['selected'];
              this.setConfig(config,setTitle);
              if(onComplete)
                onComplete();

            });
          }else{

          
            this.setConfig(value,setTitle);
            
            

            if(onComplete)
              onComplete();

          }
          
        });
    }

    loadChangeLog(onComplete:Function){
        this.http.get(Globals.rootpath+"changelog.json").subscribe((value)=>{
          
          if(value){
            this.changeLog=value;
          }

          if(onComplete)
            onComplete();
      });
    }
}