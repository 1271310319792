import { Component, OnInit,Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { ModelList } from '../../../include/modellist'
import { UntypedFormBuilder, FormGroup, FormArray, FormControl } from '@angular/forms';
import { UserService } from '../../../lib/user.service';
import { Filter, FilterMode, User } from '../../../include/structures';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Globals } from '../../../globals';
import { UserComponent } from './detail/user.component';
import { UserGroupService } from 'projects/core/src/lib/usergroup.service';

@Component({
  selector: 'app-listusers',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.sass']
})

export class UsersListComponent extends ModelList<User> implements OnInit {

  usergroups:any=[];

  get currentUser(){
    return Globals.user;
  }


  filter_status:Filter=new Filter();
  filter_role:Filter=new Filter();
  filter_search:Filter=new Filter();

  constructor(
    private userService: UserService,
    private fb1: UntypedFormBuilder,
    private modalService:NgbModal,
    private usergroupsService:UserGroupService
  ) {
    super(userService,fb1);
    this.detailViewModal=UserComponent;
    this.modulename="users";
   }


  ngOnInit(): void {
    this.title='Utenti';
    super.ngOnInit.apply(this, arguments); 
    this.usergroupsService.getGroups().subscribe((result)=>{
      this.usergroups=result;
    }); 
    
    
    this.getItems();
   
    this.filter_search.mode=FilterMode.like;
    this.filter_search.fields=[];
    this.filter_search.fields.push("u.username");
    this.filter_search.fields.push("u.name");
    this.filter_search.value="";
    this.filter.push(this.filter_search);

    this.filter_role.mode=FilterMode.normal;
    this.filter_role.fields=[];
    this.filter_role.fields.push("u.role");
    this.filter_role.value="";
    this.filter.push(this.filter_role);
    
    this.filter_status.mode=FilterMode.normal;
    this.filter_status.fields=[];
    this.filter_status.fields.push("u.enabled");
    this.filter_status.value="";
    this.filter.push(this.filter_status);
    
  }

  


  confirm_delete(id){
    confirm("Eliminare l'utente?")?this.delete(id):false;
  }




  /** Verifica se l'utente corrente può visualizzare il record */
  checkPermission(user:User){

    //se sei un SuperUser puoi vedere tutto
    switch( this.currentUser.role){
      case "superuser":
        return true;
      case "admin":
        return user.role!="superuser"?true:false;      
      case "manager":
        return (user.role!="superuser" && user.role!="admin" && user.role!="manager")?true:false;
      default:
        return (user.role!="superuser" && user.role!="admin" && user.role!="manager" && user.role!="agent")?true:false;
        
    }

  }

 
}
