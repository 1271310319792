import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FilesRoutingModule } from './files-routing.module';
import { FilesComponent } from './files.component';
import { UploadfileModule } from '../uploadfile/uploadfile.module';
import { FormsModule } from '@angular/forms';


@NgModule({
  declarations: [
    FilesComponent
  ],
  exports:[
    FilesComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    FilesRoutingModule,
    UploadfileModule
  ]
})
export class FilesModule { 

  static PERMISSIONS_ALL=0;
  static PERMISSIONS_ONLY_GROUP=1;
  static PERMISSIONS_ONLY_USER=2;


}
